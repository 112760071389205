import React, {useState, useEffect} from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import "react-datepicker/dist/react-datepicker.css";
import {getAuth} from "firebase/auth";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import QRCode from 'qrcode.react';

const UsersList = () => {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(15);
    const [search, setSearch] = useState('');
    const [showModal, setShowModal] = useState(false); // State for showing/hiding the modal
    const [selectedUser, setSelectedUser] = useState(null); // State for the selected user
    const auth = getAuth();

    const fetchUsers = async (page, limit, email) => {
        try {
            const idToken = await auth.currentUser.getIdToken(true);

            // Fetch users from the backend
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users?page=${page}&limit=${limit}&email=${email}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                }
            });
            const data = await response.json();
            setUsers(data.users);
            setHasMore(data.hasMore);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers(currentPage, pageSize, search);
    }, [currentPage, pageSize]);

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        setCurrentPage(1); // Reset to the first page when search is submitted
        setLoading(true); // Set loading state to true when applying search
        fetchUsers(1, pageSize, search); // Fetch users with search criteria
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const clearFilterValue = () => {
        setSearch('');
    };

    const handleLimitChange = (e) => {
        const newLimit = parseInt(e.target.value);
        setPageSize(newLimit);
    };

    const handleModifierClick = (user) => {
        setSelectedUser(user); // Set the selected user
        setShowModal(true); // Show the modal
    };

    const handleModalClose = () => {
        setShowModal(false); // Close the modal
        setSelectedUser(null); // Reset the selected user
    };

    const handleModify = () => {
        // Call the API to update the user status
        // Pass the selected user's ID and the new status (enable/disable)
        fetch(`${process.env.REACT_APP_API_URL}/users/updateUserStatus`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                uid: selectedUser.id,
                status: selectedUser.status
            })
        })

        setShowModal(false); // Close the modal
        setSelectedUser(null); // Reset the selected user
    };

    return (
        <div className="container mt-4">
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <button className="btn btn-primary mb-5"
                        onClick={() => fetchUsers(currentPage, pageSize, search)}>Actualiser
                </button>
            </div>

            {/* Search form */}
            <Form className="mb-4" onSubmit={handleSearchSubmit}>
                <div className="position-relative">
                    <i className="bi bi-search position-absolute top-50 start-0 translate-middle-y ms-2 text-muted"></i>
                    <Form.Control type="text" className="form-control" value={search}
                                  onChange={(e) => setSearch(e.target.value)} style={{paddingLeft: '30px'}}/>
                    {search && (
                        <i
                            className="bi bi-x position-absolute top-50 end-0 translate-middle-y me-2 text-muted"
                            onClick={clearFilterValue}
                            style={{cursor: 'pointer'}}
                        ></i>
                    )}
                </div>
                <Button style={{display: 'none'}} variant="primary" type="submit">Rechercher</Button>

            </Form>

            {/* Render users in a table */}
            <Table striped hover>
                <thead>
                <tr>
                    <th>ID Compte</th>
                    <th>Date de Création</th>
                    <th>Nom</th>
                    <th>Prénom</th>
                    <th>Email</th>
                    <th>Téléphone</th>
                    <th>Entreprise</th>
                    <th>Status</th>
                    <th>Solde ARC</th>
                    <th className="text-center">Modifier</th>
                </tr>
                </thead>
                <tbody>
                {/* Render loading message while fetching data */}
                {loading && <tr>
                    <td colSpan={9} className="text-center">Chargement ...</td>
                </tr>}
                {!loading && users.map((user, index) => (
                    <tr key={user.id}>
                        <td>{(index + 1) + ((currentPage - 1) * pageSize)}</td>
                        <td>{user.creationDate}</td>
                        <td>{user.Name}</td>
                        <td>{user.display_name}</td>
                        <td>{user.email}</td>
                        <td>{user.phone_number}</td>
                        <td>{user.entreprise}</td>
                        <td>{user.status === 'enabled' ? 'activé' : 'désactivé'}</td>
                        <td>{user.wallet_balance}</td>
                        <td className="text-end">
                            <button className="btn btn-primary" onClick={() => handleModifierClick(user)}>
                                Modifier
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>

            {/* Pagination controls */}
            <div className="d-flex justify-content-center mt-4">
                <div className="btn-group me-2" role="group" aria-label="First group">
                    <button disabled={currentPage === 1} onClick={() => handlePageChange(1)}
                            type="button" className="btn btn-outline-secondary d-flex align-items-center"
                            style={{height: '30px'}}>
                        <i className="bi bi-chevron-double-left mx-auto"></i>
                    </button>
                    <button type="button" className="btn btn-outline-secondary d-flex align-items-center"
                            disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}
                            style={{height: '30px'}}>
                        <i className="bi bi-chevron-left mx-auto"></i>
                    </button>
                    <button type="button"
                            className="btn btn-outline-secondary text-blue d-flex align-items-center justify-content-center"
                            disabled={true} style={{height: '30px'}}>
                        {currentPage}
                    </button>
                    <button type="button" className="btn btn-outline-secondary d-flex align-items-center"
                            disabled={!hasMore} onClick={() => handlePageChange(currentPage + 1)}
                            style={{height: '30px'}}>
                        <i className="bi bi-chevron-right mx-auto"></i>
                    </button>
                    <button type="button" className="btn btn-outline-secondary d-flex align-items-center"
                            disabled={!hasMore} onClick={() => handlePageChange(currentPage + 1)}
                            style={{height: '30px'}}>
                        <i className="bi bi-chevron-double-right mx-auto"></i>
                    </button>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{marginRight: '10px'}}>Afficher:</span>
                    <input type="number" className="form-control" min="15" step="15" value={pageSize}
                           onChange={handleLimitChange} style={{width: '70px', height: '30px'}}/>
                </div>
            </div>

            {/* Modal for enabling/disabling users */}
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-blue">Modification Compte</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedUser && (
                        <div>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <QRCode value={selectedUser.email}/>
                            </div>
                            <p className="text-center mt-2">{selectedUser.id}</p>
                            <Form>
                                <Form.Group as={Row} className="mb-1">
                                    <Form.Label column sm="4">Statut:</Form.Label>
                                    <Col sm="8">
                                        <Form.Check
                                            type="checkbox"
                                            label="Activé"
                                            checked={selectedUser.status === 'enabled'}
                                            onChange={() => setSelectedUser(prevUser => {
                                                    return {
                                                        ...prevUser,
                                                        status: prevUser.status === 'enabled' ? 'disabled' : 'enabled'
                                                    };
                                                }
                                            )}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-1">
                                    <Form.Label column sm="4">Nom:</Form.Label>
                                    <Col sm="8">
                                        <Form.Control plaintext readOnly defaultValue={selectedUser.Name}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-1">
                                    <Form.Label column sm="4">Prénom:</Form.Label>
                                    <Col sm="8">
                                        <Form.Control plaintext readOnly defaultValue={selectedUser.display_name}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-1">
                                    <Form.Label column sm="4">Entreprise:</Form.Label>
                                    <Col sm="8">
                                        <Form.Control plaintext readOnly defaultValue={selectedUser.entreprise}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-1">
                                    <Form.Label column sm="4">Téléphone:</Form.Label>
                                    <Col sm="8">
                                        <Form.Control plaintext readOnly defaultValue={selectedUser.phone_number}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="4">Email:</Form.Label>
                                    <Col sm="8">
                                        <Form.Control plaintext readOnly defaultValue={selectedUser.email}/>
                                    </Col>
                                </Form.Group>
                            </Form>

                            <div className="d-flex justify-content-center">
                                <Button variant="success" className="me-5" onClick={handleModify}>Valider</Button>
                                <Button variant="secondary" onClick={handleModalClose}>Annuler</Button>
                            </div>

                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UsersList;