import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { Alert, Modal, Button } from "react-bootstrap";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showModal, setShowModal] = useState(false); // State to manage modal visibility
    const [resetEmail, setResetEmail] = useState(''); // State for reset email input
    const [resetMessage, setResetMessage] = useState(''); // State for reset email response message
    const navigate = useNavigate();
    const auth = getAuth();

    const traduireMessageErreur = (errorCode) => {
        switch (errorCode) {
            case 'auth/invalid-credential':
                return "Adresse Email ou Mot de Passe erroné";
            case 'auth/invalid-email':
                return "L'adresse email fournie est invalide.";
            case 'auth/user-disabled':
                return "Ce compte utilisateur a été désactivé.";
            case 'auth/user-not-found':
                return "Aucun utilisateur trouvé avec cet email.";
            case 'auth/wrong-password':
                return "Le mot de passe est incorrect.";
            default:
                return "Erreur de connexion. Veuillez réessayer.";
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            const idToken = await user.getIdToken();

            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/client/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({ email: email })
            });

            if (response.ok) {
                navigate('/client/dashboard');
            } else {
                setErrorMessage('Erreur de connexion. Veuillez réessayer.');
            }
        } catch (error) {
            setErrorMessage(traduireMessageErreur(error.code));
        }
    };

    const handleResetPassword = async () => {
        try {
            await sendPasswordResetEmail(auth, resetEmail);
            setResetMessage('Un email de réinitialisation a été envoyé.');
        } catch (error) {
            setResetMessage(traduireMessageErreur(error.code));
        }
    };

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-center my-5">
                                <img src="/images/banner.png" alt="FPay" width={252} />
                            </div>
                            <form onSubmit={handleSubmit} className="mx-3">
                                <div className="mb-4">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        placeholder="Email"
                                        required
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="password" className="form-label">Mot de passe</label>
                                    <div className="input-group">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            className="form-control"
                                            id="password"
                                            placeholder="Mot de passe"
                                            autoComplete="current-password"
                                            required
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                                        </button>
                                    </div>
                                </div>
                                {errorMessage && <Alert variant="danger" className="mt-3">{errorMessage}</Alert>}
                                <div className="d-flex justify-content-center my-5">
                                    <button type="submit" className="btn btn-primary">Se connecter</button>
                                </div>
                                <p className="text-center">Pas de compte FPay?
                                    <a href="/client/inscription" className="link"> Commencer ici.</a>
                                </p>
                                <p className="text-center">Mot de passe oublié?
                                    <a href="#" className="link" onClick={() => setShowModal(true)}> Réinitialiser mon mot de passe</a>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal for password reset */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Réinitialiser le mot de passe</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-4">
                        <label htmlFor="resetEmail" className="form-label">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            id="resetEmail"
                            placeholder="Email"
                            required
                            value={resetEmail}
                            onChange={e => setResetEmail(e.target.value)}
                        />
                    </div>
                    {resetMessage && <Alert variant="info">{resetMessage}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Fermer</Button>
                    <Button variant="primary" onClick={handleResetPassword}>Envoyer</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Login;