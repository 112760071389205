import React, { useState, useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Sidebar from './Sidebar';
import Spinner from 'react-bootstrap/Spinner';
import { Navbar } from 'react-bootstrap';

const Layout = ({ children }) => {
    const [userDisplayName, setUserDisplayName] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [showSidebar, setShowSidebar] = useState(false);
    const location = useLocation();
    const auth = getAuth();

    // Check for user authentication state
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setIsAuthenticated(!!user);
            if (user) {
                setUserDisplayName(user.displayName); // Set user's displayName
                // Check if user has custom claims
                if (user.getIdTokenResult) {
                    const idTokenResult = await user.getIdTokenResult();
                    const customClaims = idTokenResult.claims;
                    const userRole = customClaims.role;
                    setUserRole(userRole);
                }
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [auth]);

    // Determine whether to show the sidebar
    const shouldShowSidebar = location.pathname !== '/login' && location.pathname !== '/client/login' && location.pathname !== '/client/inscription';

    if (isAuthenticated === null) {
        // Use Spinner for the loading state
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner animation="border" />
            </div>
        );
    }

    if (!isAuthenticated && !['/login', '/client/inscription', '/client/login'].includes(location.pathname)) {
        // Redirect to login page if not authenticated and not on login/inscription page
        return <Navigate to="/login" />;
    }

    const handleToggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    return (
        <>
            {/* Navbar for smaller screens */}
            <Navbar bg="light" expand="md" className="d-flex d-md-none justify-content-between">
                {shouldShowSidebar && (
                    <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleToggleSidebar} className="ms-3" />
                )}
                <Navbar.Brand href="/" className="mx-auto text-center">
                    <img src="/images/banner.png" alt="FPay" width={200}/>
                </Navbar.Brand>
            </Navbar>

            {/* Sidebar and content */}
            <div className="d-flex">
                {shouldShowSidebar &&  <Sidebar show={showSidebar} setShow={setShowSidebar} userName={userDisplayName} userRole={userRole} />}
                <main className="flex-grow-1 p-3">
                    {children}
                </main>
            </div>
        </>
    );
};

export default Layout;
