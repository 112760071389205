import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Button} from 'react-bootstrap';

const DailyTransactions = () => {

    const data = [
        {
            "label": "AriaryCoin",
            "transactions": [
                {
                    "label": "ARC",
                    "amount": 0,
                    "number": 0
                }
            ],
            "sortant": [
                {
                    "label": "ARC",
                    "amount": 0,
                    "number": 0
                }
            ]
        },
        {
            "label": "Mobile Money",
            "transactions": [
                {
                    "label": "Mvola 1",
                    "amount": 0,
                    "number": 0
                },
                {
                    "label": "Mvola 2",
                    "amount": 0,
                    "number": 0
                },
                {
                    "label": "Orange Money 1",
                    "amount": 0,
                    "number": 0
                },
                {
                    "label": "Orange Money 2",
                    "amount": 0,
                    "number": 0
                },
                {
                    "label": "Airtel Money 1",
                    "amount": 0,
                    "number": 0
                },
                {
                    "label": "Airtel Money 2",
                    "amount": 0,
                    "number": 0
                }
            ],
            "sortant": [
                {
                    "label": "Mvola 1",
                    "amount": 0,
                    "number": 0
                },
                {
                    "label": "Mvola 2",
                    "amount": 0,
                    "number": 0
                },
                {
                    "label": "Orange Money 1",
                    "amount": 0,
                    "number": 0
                },
                {
                    "label": "Orange Money 2",
                    "amount": 0,
                    "number": 0
                },
                {
                    "label": "Airtel Money 1",
                    "amount": 0,
                    "number": 0
                },
                {
                    "label": "Airtel Money 2",
                    "amount": 0,
                    "number": 0
                }
            ]
        },
        {
            "label": "Espèces",
            "transactions": [
                {
                    "label": "Ariary",
                    "amount": 0,
                    "number": 0
                }
            ],
            "sortant": [
                {
                    "label": "Ariary",
                    "amount": 0,
                    "number": 0
                }
            ]
        },
        {
            "label": "Banques",
            "transactions": [
                {
                    "label": "Banque Ariary",
                    "amount": 0,
                    "number": 0
                },
                {
                    "label": "Banque Euro",
                    "amount": 0,
                    "number": 0
                }
            ],
            "sortant": [
                {
                    "label": "Banque Ariary",
                    "amount": 0,
                    "number": 0
                },
                {
                    "label": "Banque Euro",
                    "amount": 0,
                    "number": 0
                }
            ]
        }
    ]

    return (
        <div className="container mt-5">
            <div className="text-center my-4">
                <h2 className="myTitle">Transaction du jour</h2>
            </div>
            <Row xs={1} md={2} className="g-4">
                <div>
                    <div className="text-center my-4">
                        <h2 className="myTitle">Entrant</h2>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="max-width-container2">
                            <Row xs={1} md={2} className="g-4">
                                <Col>
                                    <h3 className="titleSolde">AriaryCoin</h3>
                                    <Card>
                                        <Card.Body className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <Card.Title><span
                                                    className="fw-bolder">ARC</span></Card.Title>
                                                <Card.Title>{data[0].transactions[0].amount} </Card.Title>
                                            </div>
                                            <div>
                                                <Card.Title><span
                                                    className="fw-bolder">Nombre de transaction</span></Card.Title>
                                                <Card.Title>{data[0].transactions[0].number}</Card.Title>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <h3 className="titleSolde">Espèces</h3>
                                    <Card>
                                        <Card.Body className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <Card.Title><span
                                                    className="fw-bolder">Ariary</span></Card.Title>
                                                <Card.Title>{data[2].transactions[0].amount}</Card.Title>
                                            </div>
                                            <div>
                                                <Card.Title><span
                                                    className="fw-bolder">Nombre de transaction</span></Card.Title>
                                                <Card.Title>{data[2].transactions[0].number}</Card.Title>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            {data.map((item, index) => (
                                <div key={index} className="mt-3">
                                    {index === 0 || index === 2 ? (
                                        <>
                                        </>
                                    ) : (
                                        <>
                                            <h3 className="titleSolde">{item.label}</h3>
                                            <Row xs={1} md={2} className="g-4">
                                                {item.transactions.map((transaction, index) => (
                                                    <Col key={index}>
                                                        <Card>
                                                            <Card.Body
                                                                className="d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <Card.Title><span
                                                                        className="fw-bolder">{transaction.label}</span></Card.Title>
                                                                    <Card.Title>{transaction.amount}</Card.Title>
                                                                </div>
                                                                <div>
                                                                    <Card.Title><span
                                                                        className="fw-bolder">Nombre de transaction</span></Card.Title>
                                                                    <Card.Title>{transaction.number}</Card.Title>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>


                <div>
                    <div className="text-center my-4">
                        <h2 className="myTitle">Sortant</h2>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="max-width-container2">
                            <Row xs={1} md={2} className="g-4">
                                <Col>
                                    <h3 className="titleSolde">AriaryCoin</h3>
                                    <Card>
                                        <Card.Body className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <Card.Title><span
                                                    className="fw-bolder">ARC</span></Card.Title>
                                                <Card.Title>{data[0].sortant[0].amount} </Card.Title>
                                            </div>
                                            <div>
                                                <Card.Title><span
                                                    className="fw-bolder">Nombre de transaction</span></Card.Title>
                                                <Card.Title>{data[0].sortant[0].number}</Card.Title>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <h3 className="titleSolde">Espèces</h3>
                                    <Card>
                                        <Card.Body className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <Card.Title><span
                                                    className="fw-bolder">Ariary</span></Card.Title>
                                                <Card.Title>{data[2].sortant[0].amount}</Card.Title>
                                            </div>
                                            <div>
                                                <Card.Title><span
                                                    className="fw-bolder">Nombre de transaction</span></Card.Title>
                                                <Card.Title>{data[2].sortant[0].number}</Card.Title>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            {data.map((item, index) => (
                                <div key={index} className="mt-3">
                                    {index === 0 || index === 2 ? (
                                        <>
                                        </>
                                    ) : (
                                        <>
                                            <h3 className="titleSolde">{item.label}</h3>
                                            <Row xs={1} md={2} className="g-4">
                                                {item.sortant.map((transaction, index) => (
                                                    <Col key={index}>
                                                        <Card>
                                                            <Card.Body
                                                                className="d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <Card.Title><span
                                                                        className="fw-bolder">{transaction.label}</span></Card.Title>
                                                                    <Card.Title>{transaction.amount}</Card.Title>
                                                                </div>
                                                                <div>
                                                                    <Card.Title><span
                                                                        className="fw-bolder">Nombre de transaction</span></Card.Title>
                                                                    <Card.Title>{transaction.number}</Card.Title>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Row>
        </div>
    );
};

export default DailyTransactions;
