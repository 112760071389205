import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Button} from 'react-bootstrap';

const DailyTransactions = () => {

    const data = [
        {
            "label": "Nombre de transaction du jour",
            "entrant":
                {
                    "amount": 0,
                    "number": 0
                },
            "sortant":
                {
                    "amount": 0,
                    "number": 0
                }
        },
        {
            "label": "Nombre de transaction de la semaine",
            "entrant":
                {
                    "amount": 0,
                    "number": 0
                },
            "sortant":
                {
                    "amount": 0,
                    "number": 0
                }
        },
        {
            "label": "Nombre de transaction du mois",
            "entrant":
                {
                    "amount": 0,
                    "number": 0
                }
            ,
            "sortant":
                {
                    "amount": 0,
                    "number": 0
                }

        },
        {
            "label": "Nombre total de transaction",
            "entrant":
                {
                    "amount": 0,
                    "number": 0
                }
            ,
            "sortant":
                {
                    "amount": 0,
                    "number": 0
                }

        },

    ]
    const [walletBalances, setWalletBalances] = useState({});
    useEffect(() => {
        const fetchWalletBalances = async () => {
            try {
                const response = await fetch('https://wl.fpay.mg');
                const data = await response.json();
                setWalletBalances(data);
            } catch (error) {
                console.error('Error fetching wallet balances:', error);
            }
        };

        fetchWalletBalances();
    }, []);

    return (
        <div className="container mt-5 text-center">
            <h1 className="text-center mt-5 text-blue">DASHBOARD</h1>

            <Card style={{ maxWidth: '200px' }} className="mx-auto mt-5">
                <Card.Body>
                    <div>
                        <Card.Title><span className="fw-bolder">Solde Ariarycoin</span></Card.Title>
                        <Card.Title>{walletBalances.ARC?.toLocaleString()}</Card.Title>
                    </div>
                </Card.Body>
            </Card>


            <Row xs={1} md={2} className="g-4">
                <div>
                    <div className="text-center my-4">
                        <h2 className="myTitle">Entrant</h2>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="max-width-container2">
                            <Row xs={1} md={2} className="g-4">
                                {data.map((item, index) => (
                                    <>
                                        <Col className="text-center">
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title><span
                                                        className="fw-bolder">{item.label}</span></Card.Title>
                                                    <Card.Title>{item.entrant.number}</Card.Title>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col className="text-center">
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title><span
                                                        className="fw-bolder">Montant</span></Card.Title>
                                                    <Card.Title>{item.entrant.amount} </Card.Title>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </>
                                ))}
                            </Row>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="text-center my-4">
                        <h2 className="myTitle">Sortant</h2>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="max-width-container2">
                            <Row xs={1} md={2} className="g-4">
                                {data.map((item, index) => (
                                    <>
                                        <Col className="text-center">
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title><span
                                                        className="fw-bolder">{item.label}</span></Card.Title>
                                                    <Card.Title>{item.sortant.number}</Card.Title>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col className="text-center">
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title><span
                                                        className="fw-bolder">Montant</span></Card.Title>
                                                    <Card.Title>{item.sortant.amount} </Card.Title>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </>
                                ))}
                            </Row>
                        </div>
                    </div>
                </div>
            </Row>
        </div>
    );
};

export default DailyTransactions;
