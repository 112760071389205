import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Button} from 'react-bootstrap';
import {getAuth} from "firebase/auth";

const CourComponent = () => {
    const [cours, setCours] = useState([]);
    const [editingCour, setEditingCour] = useState(null);
    const auth = getAuth();

    useEffect(() => {
        const fetchCours = async () => {
            try {
                const idToken = await auth.currentUser.getIdToken(true);

                const response = await fetch(`${process.env.REACT_APP_API_URL}/cours`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${idToken}`
                    }
                });
                const data = await response.json();

                // Define the required currency ids in the desired order
                const requiredCurrencyIds = ['arc_euro', 'euro_arc', 'arc_ead', 'ead_arc'];

                // Filter the data to include only the required currencies
                const filteredCours = data.filter(cour => requiredCurrencyIds.includes(cour.id));

                // Create an object with cour id as keys and values as values
                const coursObject = {};

                // Sort the filtered currencies based on their index in the requiredCurrencyIds array
                requiredCurrencyIds.forEach(currencyId => {
                    const currencyData = filteredCours.find(cour => cour.id === currencyId);
                    if (currencyData) {
                        coursObject[currencyData.id] = currencyData.value;
                    }
                });

                setCours(coursObject);
            } catch (error) {
                console.error('Error fetching cours:', error);
            }
        };

        fetchCours();
    }, []);

    const handleUpdateCour = async (cour) => {
        try {
            const idToken = await auth.currentUser.getIdToken(true);

            const value = cours[cour];
            const response = await fetch(`${process.env.REACT_APP_API_URL}/cours/${cour}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({value})
            });
            // Check if update was successful
            if (response.ok) {
                setEditingCour(null); // Reset editing state
            }
            console.log(`Cour updated for: ${cour}`);
        } catch (error) {
            console.error(`Error updating cour for ${cour}:`, error);
        }
    };

    const handleEdit = (cour) => {
        setEditingCour(cour);
    };

    const handleInputChange = (event, cour) => {
        const {value} = event.target;
        setCours(prevCours => ({
            ...prevCours,
            [cour]: parseFloat(value)
        }));
    };

    return (
        <div className="container mt-4">
            <h1 className="text-center mt-5 text-blue">COURS</h1>
            <div className="d-flex justify-content-center">
                <div className="max-width-container">
                    <div className="mt-5">
                        <Row xs={1} md={2} className="g-4">
                            {Object.entries(cours).map(([cour, value]) => (
                                <Col key={cour}>
                                    <Card>
                                        <Card.Body className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <Card.Title>{cour.toUpperCase().replace('_', ' / ')}</Card.Title>
                                                {editingCour === cour ? (
                                                    <input
                                                        type="number"
                                                        value={value}
                                                        onChange={(e) => handleInputChange(e, cour)}
                                                    />
                                                ) : (
                                                    <Card.Text>
                                                        Value: {value.toLocaleString()}
                                                    </Card.Text>
                                                )}
                                            </div>
                                            <div>
                                                {editingCour === cour ? (
                                                    <Button onClick={() => handleUpdateCour(cour)}>Valider</Button>
                                                ) : (
                                                    <Button onClick={() => handleEdit(cour)}>Modifier</Button>
                                                )}
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CourComponent;
