import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Button} from 'react-bootstrap';
import {getAuth} from "firebase/auth";

const DailyTransactions = () => {

    const data = [
        {
            "label": "Nombre de transaction du jour",
            "entrant":
                {
                    "amount": 0,
                    "number": 0
                },
            "sortant":
                {
                    "amount": 0,
                    "number": 0
                }
        },
        {
            "label": "Nombre de transaction de la semaine",
            "entrant":
                {
                    "amount": 0,
                    "number": 0
                },
            "sortant":
                {
                    "amount": 0,
                    "number": 0
                }
        },
        {
            "label": "Nombre de transaction du mois",
            "entrant":
                {
                    "amount": 0,
                    "number": 0
                }
            ,
            "sortant":
                {
                    "amount": 0,
                    "number": 0
                }

        },
        {
            "label": "Nombre total de transaction",
            "entrant":
                {
                    "amount": 0,
                    "number": 0
                }
            ,
            "sortant":
                {
                    "amount": 0,
                    "number": 0
                }

        },

    ]
    const [solde, setSolde] = useState(0);
    const auth = getAuth();

    const fetchSolde = async () => {
        try {
            // Get the ID token of the current user
            const idToken = await auth.currentUser.getIdToken(true);

            // Get the UID of the current user
            const uid = auth.currentUser.uid;

            // Make the API request with the ID token and UID
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${uid}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch solde');
            }

            const data = await response.json();

            setSolde(data.solde);
        } catch (error) {
            console.error('Error fetching fees:', error);
        }
    };

    useEffect(() => {
        fetchSolde();
    }, []);

    return (
        <div className="container mt-5">
            <h1 className="text-center mt-5 text-blue">DASHBOARD</h1>

            <Card style={{ maxWidth: '200px' }} className="mx-auto mt-5">
                <Card.Body className="d-flex justify-content-between align-items-center">
                    <div>
                        <Card.Title><span className="fw-bolder">Solde Ariarycoin</span></Card.Title>
                        <Card.Title>{solde.toLocaleString()}</Card.Title>
                    </div>
                </Card.Body>
            </Card>


            <Row xs={1} md={2} className="g-4">
                <div>
                    <div className="text-center my-4">
                        <h2 className="myTitle">Entrant</h2>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="max-width-container2">
                            <Row xs={1} md={2} className="g-4">
                                {data.map((item, index) => (
                                    <>
                                        <Col>
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title><span
                                                        className="fw-bolder">{item.label}</span></Card.Title>
                                                    <Card.Title>{item.entrant.number}</Card.Title>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title><span
                                                        className="fw-bolder">Montant</span></Card.Title>
                                                    <Card.Title>{item.entrant.amount} </Card.Title>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </>
                                ))}
                            </Row>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="text-center my-4">
                        <h2 className="myTitle">Sortant</h2>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="max-width-container2">
                            <Row xs={1} md={2} className="g-4">
                                {data.map((item, index) => (
                                    <>
                                        <Col>
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title><span
                                                        className="fw-bolder">{item.label}</span></Card.Title>
                                                    <Card.Title>{item.sortant.number}</Card.Title>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title><span
                                                        className="fw-bolder">Montant</span></Card.Title>
                                                    <Card.Title>{item.sortant.amount} </Card.Title>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </>
                                ))}
                            </Row>
                        </div>
                    </div>
                </div>
            </Row>
        </div>
    );
};

export default DailyTransactions;
