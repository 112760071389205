import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { getAuth } from "firebase/auth";
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import countries from '../countries-FR.json';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const UsersList = ({ role }) => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [search, setSearch] = useState('');
    const [hasMore, setHasMore] = useState(false);
    const auth = getAuth();

    const fetchUsers = async (page, limit, email) => {
        try {
            const idToken = await auth.currentUser.getIdToken(true);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/roles/${role}?page=${page}&limit=${limit}&email=${email}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                }
            });
            const data = await response.json();
            setUsers(data.users);
            setHasMore(data.hasMore);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers(currentPage, pageSize, search);
    }, [currentPage, pageSize]);

    const handleModifyClick = (user) => {
        setSelectedUser(user);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleSaveChanges = async () => {
        // Update user data in the state
        try {
            const idToken = await auth.currentUser.getIdToken(true);
            const { uid, phone_number, display_name, Name, entreprise, country } = selectedUser; // Assuming Name corresponds to first_name
            const requestBody = {
                uid,
                phone_number,
                display_name,
                first_name: Name, // Assuming Name corresponds to first_name
                entreprise,
                country,
                status: selectedUser.status
            };

            await fetch(`${process.env.REACT_APP_API_URL}/users/updateUser`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify(requestBody)
            });
            fetchUsers();
        } catch (error) {
            console.error('Error updating user:', error);
        }

        // Close the modal
        setShowModal(false);
    };

    const handleNameChange = (e) => {
        setSelectedUser(prevUser => ({
            ...prevUser,
            Name: e.target.value
        }));
    };

    const handleDisplayNameChange = (e) => {
        setSelectedUser(prevUser => ({
            ...prevUser,
            display_name: e.target.value
        }));
    };

    const handleEntrepriseChange = (e) => {
        setSelectedUser(prevUser => ({
            ...prevUser,
            entreprise: e.target.value
        }));
    };

    const handleCountryChange = (e) => {
        setSelectedUser(prevUser => ({
            ...prevUser,
            country: e.target.value
        }));
    };

    const handlePhoneNumberChange = (e) => {
        setSelectedUser(prevUser => ({
            ...prevUser,
            phone_number: e.target.value
        }));
    };

    const handleEmailChange = (e) => {
        setSelectedUser(prevUser => ({
            ...prevUser,
            email: e.target.value
        }));
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleLimitChange = (e) => {
        setPageSize(parseInt(e.target.value));
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        fetchUsers(currentPage, pageSize, search);
    };

    const clearFilterValue = () => {
        setSearch('');
    };

    return (
        <div className="container mt-4">
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Link to="/newAdmin" className="btn btn-primary me-3" state={{adminID: auth.currentUser.uid}}>
                    Créer Compte
                </Link>
                <button className="btn btn-primary" onClick={fetchUsers}>Actualiser</button>
            </div>

            {/* Search form */}
            <Form className="my-4" onSubmit={handleSearchSubmit}>
                <div className="position-relative">
                    <i className="bi bi-search position-absolute top-50 start-0 translate-middle-y ms-2 text-muted"></i>
                    <Form.Control type="text" className="form-control" value={search}
                                  onChange={(e) => setSearch(e.target.value)} style={{paddingLeft: '30px'}}/>
                    {search && (
                        <i
                            className="bi bi-x position-absolute top-50 end-0 translate-middle-y me-2 text-muted"
                            onClick={clearFilterValue}
                            style={{cursor: 'pointer'}}
                        ></i>
                    )}
                </div>
                <Button style={{display: 'none'}} variant="primary" type="submit">Rechercher</Button>

            </Form>

            <Table striped hover className="mt-5">
                <thead>
                <tr>
                    <th>ID Compte</th>
                    <th>Date de Création</th>
                    <th>Nom</th>
                    <th>Prénom</th>
                    <th>Entreprise</th>
                    <th>Email</th>
                    <th>Téléphone</th>
                    <th>Status</th>
                    { role !== 'agent' && <th>Solde ARC</th>}
                    <th className="text-center">Modifier</th>
                </tr>
                </thead>
                <tbody>
                {loading ? (
                    <tr>
                        <td colSpan={9} className="text-center">Chargement ...</td>
                    </tr>
                ) : (
                    users.map((user, index) => (
                        <tr key={user.uid}>
                            <td>{index + 1}</td>
                            <td>{user.creationDate}</td>
                            <td>{user.Name}</td>
                            <td>{user.display_name}</td>
                            <td>{user.entreprise}</td>
                            <td>{user.email}</td>
                            <td>{user.phone_number}</td>
                            <td>{user.status === 'enabled' ? 'activé' : 'désactivé'}</td>
                            { role !== 'agent' && <td>{user.wallet_balance?.toLocaleString()}</td>}
                            <td className="text-end">
                                <button className="btn btn-primary" onClick={() => handleModifyClick(user)}>
                                    Modifier
                                </button>
                            </td>
                        </tr>
                    ))
                )}
                </tbody>
            </Table>

            {/* Pagination controls */}
            <div className="d-flex justify-content-center mt-4">
                <div className="btn-group me-2" role="group" aria-label="First group">
                    <button disabled={currentPage === 1} onClick={() => handlePageChange(1)}
                            type="button" className="btn btn-outline-secondary d-flex align-items-center"
                            style={{height: '30px'}}>
                        <i className="bi bi-chevron-double-left mx-auto"></i>
                    </button>
                    <button type="button" className="btn btn-outline-secondary d-flex align-items-center"
                            disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}
                            style={{height: '30px'}}>
                        <i className="bi bi-chevron-left mx-auto"></i>
                    </button>
                    <button type="button"
                            className="btn btn-outline-secondary text-blue d-flex align-items-center justify-content-center"
                            disabled={true} style={{height: '30px'}}>
                        {currentPage}
                    </button>
                    <button type="button" className="btn btn-outline-secondary d-flex align-items-center"
                            disabled={!hasMore} onClick={() => handlePageChange(currentPage + 1)}
                            style={{height: '30px'}}>
                        <i className="bi bi-chevron-right mx-auto"></i>
                    </button>
                    <button type="button" className="btn btn-outline-secondary d-flex align-items-center"
                            disabled={!hasMore} onClick={() => handlePageChange(currentPage + 1)}
                            style={{height: '30px'}}>
                        <i className="bi bi-chevron-double-right mx-auto"></i>
                    </button>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{marginRight: '10px'}}>Afficher:</span>
                    <input type="number" className="form-control" min="15" step="15" value={pageSize}
                           onChange={handleLimitChange} style={{width: '70px', height: '30px'}}/>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-blue">Modification Compte</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedUser && (
                        <div>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <QRCode value={selectedUser.email}/>
                            </div>
                            <p className="text-center mt-2">{selectedUser.uid}</p>
                            <Form>
                                <Form.Group as={Row} className="mb-1">
                                    <Form.Label column sm="4">Statut:</Form.Label>
                                    <Col sm="8">
                                        <Form.Check
                                            type="checkbox"
                                            label="Activé"
                                            checked={selectedUser.status === 'enabled'}
                                            onChange={() => setSelectedUser(prevUser => {
                                                    return {
                                                        ...prevUser,
                                                        status: prevUser.status === 'enabled' ? 'disabled' : 'enabled'
                                                    };
                                                }
                                            )}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Nom</Form.Label>
                                    <Form.Control type="text" value={selectedUser.Name} onChange={handleNameChange} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Prénom</Form.Label>
                                    <Form.Control type="text" value={selectedUser.display_name} onChange={handleDisplayNameChange} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Entreprise</Form.Label>
                                    <Form.Control type="text" value={selectedUser.entreprise} onChange={handleEntrepriseChange} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Pays</Form.Label>
                                    <Form.Control as="select" value={selectedUser.country} onChange={handleCountryChange}>
                                        {Object.entries(countries).map(([key, value]) => (
                                            <option key={key} value={key}>{value}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Téléphone</Form.Label>
                                    <Form.Control type="text" value={selectedUser.phone_number} onChange={handlePhoneNumberChange} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" value={selectedUser.email} onChange={handleEmailChange} />
                                </Form.Group>
                            </Form>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSaveChanges}>
                        Valider
                    </Button>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Annuler
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UsersList;