import React, {useState, useEffect} from 'react';
import {getAuth} from "firebase/auth";
import Button from "react-bootstrap/Button";
import {Modal} from "react-bootstrap";

const Fees = () => {
    const [fees, setFees] = useState(null);
    const auth = getAuth();
    const [loading, setLoading] = useState(true);
    const [editableIntervals, setEditableIntervals] = useState({});
    const [activeTab, setActiveTab] = useState('');
    const [activeSubTab, setActiveSubTab] = useState('Madagascar');
    const [activeGenreTab, setActiveGenreTab] = useState('cashpoint_client');
    const [showModal, setShowModal] = useState(false);
    const [selectedType, setSelectedType] = useState('');
    const [selectedSubtype, setSelectedSubtype] = useState('');
    const [agentfpay_cashpoint, setAgentfpay_cashpoint] = useState([]);
    const [agentfpay_client, setAgentfpay_client] = useState([]);
    const [cashpoint_client, setCashpoint_client] = useState([]);
    const [activeGenre, setActiveGenre] = useState({});

    // Function to handle opening modal and setting selected type and subtype
    const handleAddIntervalClick = (type, subtype) => {
        setSelectedType(type);
        setSelectedSubtype(subtype);
        setShowModal(true);
    };

    // Function to handle closing modal
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const fetchFees = async () => {
        setLoading(true);
        try {
            const idToken = await auth.currentUser.getIdToken(true);

            const response = await fetch(`${process.env.REACT_APP_API_URL}/transactions/fees`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch fees');
            }
            const data = await response.json();

            console.log("data", data);

            // Set the fetched fees data to state
            setFees(data);
            setCashpoint_client(data.Vendre.cashpoint_client);
            setAgentfpay_cashpoint(data.Vendre.agentfpay_cashpoint);
            setAgentfpay_client(data.Vendre.agentfpay_client);

            setActiveGenre(data.Vendre.cashpoint_client);

            // Initialize editable intervals state
            const initialEditableIntervals = {};
            Object.entries(data).forEach(([type, subtypes]) => {
                Object.entries(subtypes).forEach(([subtype, intervals]) => {
                    if (Array.isArray(intervals)) {
                        intervals.forEach((interval, index) => {
                            initialEditableIntervals[`${type}_${subtype}_${index}`] = false;
                        });
                    } else {
                        Object.entries(intervals).forEach(([subsubtype, subintervals]) => {
                            Object.entries(subintervals).forEach(([subsubtype, subintervals]) => {
                                subintervals.forEach((interval, index) => {
                                    initialEditableIntervals[`${type}_${subtype}_${index}`] = false;
                                });
                            });
                        });
                    }
                });
            });
            setEditableIntervals(initialEditableIntervals);

            // Set the active tab to the first type
            setActiveTab(Object.keys(data)[0]);
        } catch (error) {
            console.error('Error fetching fees:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFees();
    }, []);

    const handleTabClick = (type) => {
        setActiveTab(type);
        setActiveSubTab('Madagascar'); // Reset sub-tab to Madagascar when changing tabs
    };

    const handleSubTabClick = (subTab) => {
        setActiveSubTab(subTab);
    };

    const handleGenreClick = (subTab) => {
        setActiveGenreTab(subTab);
        switch (subTab) {
            case 'cashpoint_client':
                setActiveGenre(cashpoint_client);
                break;
            case 'agentfpay_cashpoint':
                setActiveGenre(agentfpay_cashpoint);
                break;
            case 'agentfpay_client':
                setActiveGenre(agentfpay_client);
                break;
            default:
                setActiveGenre(cashpoint_client);
        }
    };

    const handleEdit = (type, subtype, index) => {
        setEditableIntervals(prevState => ({
            ...prevState,
            [`${type}_${subtype}_${index}`]: true
        }));
    };

    const handleInputChange = (e, type, subtype, index) => {
        const {value} = e.target;
        setEditableIntervals(prevState => ({
            ...prevState,
            [`${type}_${subtype}_${index}`]: value
        }));
    };

    const handleSubmit = async (type, subtype, index, amount, min, max) => {
        try {
            const idToken = await auth.currentUser.getIdToken(true);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/transactions/fees/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({
                    type,
                    subtype,
                    index,
                    amount: editableIntervals[`${type}_${subtype}_${index}`],
                    document: `${subtype}-${min.toString()}-${max.toString()}`
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update fee');
            }

            // Refetch fees data
            const updatedFees = {...fees};
            updatedFees[type][subtype][index].amount = editableIntervals[`${type}_${subtype}_${index}`];
            setFees(updatedFees);

            // Reset editing state
            setEditableIntervals(prevState => ({
                ...prevState,
                [`${type}_${subtype}_${index}`]: false
            }));
        } catch (error) {
            console.error('Error updating fee:', error);
        }
    };

    const [newInterval, setNewInterval] = useState({min: '', max: '', amount: ''});

    const handleNewMinChange = (e) => {
        setNewInterval({...newInterval, min: e.target.value});
    };

    const handleNewMaxChange = (e) => {
        setNewInterval({...newInterval, max: e.target.value});
    };

    const handleInfinityChange = (e) => {
        const isChecked = e.target.checked;
        setNewInterval(prevInterval => ({
            ...prevInterval,
            max: isChecked ? 'inf' : ''
        }));
    };

    const handleNewMontantChange = (e) => {
        setNewInterval({...newInterval, amount: e.target.value});
    };

    const handleSubmitNewInterval = async () => {
        try {
            const idToken = await auth.currentUser.getIdToken(true);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/transactions/fees/addInterval`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({
                    type: activeTab,
                    subtype: selectedSubtype,
                    min: newInterval.min,
                    max: newInterval.max,
                    amount: newInterval.amount
                })
            });

            if (!response.ok) {
                throw new Error('Failed to add new interval');
            }

            console.log('activeTab', activeTab);
            console.log('selectedSubtype', selectedSubtype);

            // Refetch fees data after adding the new interval
            await fetchFees();

            // Reset new interval state
            setNewInterval({min: '', max: '', amount: ''});
        } catch (error) {
            console.error('Error adding new interval:', error);
        }
    };

    const getLabel = (type) => {
        switch (type) {
            case 'mobile':
                return 'Mobile Money';
            case 'compte':
                return 'Compte à Compte';
            case 'especes':
                return 'Bon d’achat';
            case 'paypal':
                return 'Paypal';
            case 'virement':
                return 'Virement Bancaire';
            case 'carte':
                return 'Carte bleu';
            case 'telma':
                return 'Mobile Money Telma';
            case 'airtel':
                return 'Mobile Money Airtel';
            case 'orange':
                return 'Mobile Money Orange';
            default:
                return type;
        }
    }

// Function to render intervals
    const renderIntervals = (intervalsEntries, type) => (
        <>
            {intervalsEntries
                .sort(([subtypeA], [subtypeB]) => {
                    const labelA = getLabel(subtypeA.split('@')[0].replace('int_', ''));
                    const labelB = getLabel(subtypeB.split('@')[0].replace('int_', ''));
                    return labelA.localeCompare(labelB);
                })
                .map(([subtype, intervals]) => (
                    <div key={subtype}>
                        <h4 className="text-center mt-3 fw-bold">{getLabel(subtype.split('@')[0].replace('int_', ''))}</h4>
                        <table className="table text-end">
                            <thead>
                            <tr>
                                <th>Min</th>
                                <th>Max</th>
                                <th className="text-right">Montant</th>
                                <th>
                                    <button className="btn btn-yellow" onClick={() => handleAddIntervalClick(type, subtype)}>Ajouter Frais</button>
                                </th>
                            </tr>
                            </thead>
                            <tbody className="table-group-divider">
                            {intervals.map((interval, index) => (
                                <tr key={index}>
                                    <td>{interval.min.toLocaleString()}</td>
                                    <td>{interval.max === 'Infinity' || interval.max === 'inf' ? 'ou plus' : interval.max.toLocaleString()}</td>
                                    <td className="text-right">
                                        {editableIntervals[`${type}_${subtype}_${index}`] ? (
                                            <input
                                                type="number"
                                                value={editableIntervals[`${type}_${subtype}_${index}`] || interval.amount}
                                                onChange={(e) => handleInputChange(e, type, subtype, index)}
                                            />
                                        ) : (
                                            <p>{interval.amount.toLocaleString()}</p>
                                        )}
                                    </td>
                                    <td>
                                        {editableIntervals[`${type}_${subtype}_${index}`] ? (
                                            <>
                                                <button className="btn btn-primary" onClick={() => handleSubmit(type, subtype, index, interval.amount, interval.min, interval.max)}>Valider</button>
                                                <button className="btn btn-secondary" onClick={() => {
                                                    setEditableIntervals(prevState => ({
                                                        ...prevState,
                                                        [`${type}_${subtype}_${index}`]: false
                                                    }));
                                                }}>Annuler</button>
                                            </>
                                        ) : (
                                            <button className="btn btn-primary" onClick={() => handleEdit(type, subtype, index)}>Modifier</button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                ))}
        </>
    );

    // Function to render intervals
    const renderIntervals2 = (intervalsEntries, type) => (
        <>
            {intervalsEntries
                .sort(([subtypeA], [subtypeB]) => {
                    const labelA = getLabel(subtypeA);
                    const labelB = getLabel(subtypeB);
                    return labelA.localeCompare(labelB);
                })
                .map(([subtype, intervals]) => (
                    <div key={subtype}>
                        <h4 className="text-center mt-3 fw-bold">{getLabel(subtype)}</h4>
                        <table className="table text-end">
                            <thead>
                            <tr>
                                <th>Min</th>
                                <th>Max</th>
                                <th className="text-right">Montant</th>
                                <th>
                                    <button className="btn btn-yellow" onClick={() => handleAddIntervalClick(type, subtype)}>Ajouter Frais</button>
                                </th>
                            </tr>
                            </thead>
                            <tbody className="table-group-divider">
                            {intervals.map((interval, index) => (
                                <tr key={index}>
                                    <td>{interval.min.toLocaleString()}</td>
                                    <td>{interval.max === 'Infinity' || interval.max === 'inf' ? 'ou plus' : interval.max.toLocaleString()}</td>
                                    <td className="text-right">
                                        {editableIntervals[`${type}_${subtype}_${index}`] ? (
                                            <input
                                                type="number"
                                                value={editableIntervals[`${type}_${subtype}_${index}`] || interval.amount}
                                                onChange={(e) => handleInputChange(e, type, subtype, index)}
                                            />
                                        ) : (
                                            <p>{interval.amount.toLocaleString()}</p>
                                        )}
                                    </td>
                                    <td>
                                        {editableIntervals[`${type}_${subtype}_${index}`] ? (
                                            <>
                                                <button className="btn btn-primary" onClick={() => handleSubmit(type, subtype, index, interval.amount, interval.min, interval.max)}>Valider</button>
                                                <button className="btn btn-secondary" onClick={() => {
                                                    setEditableIntervals(prevState => ({
                                                        ...prevState,
                                                        [`${type}_${subtype}_${index}`]: false
                                                    }));
                                                }}>Annuler</button>
                                            </>
                                        ) : (
                                            <button className="btn btn-primary" onClick={() => handleEdit(type, subtype, index)}>Modifier</button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                ))}
        </>
    );

    return (
        <div className="container mt-4">
            <h1 className="text-center mt-5 text-blue">FRAIS</h1>

            {loading && <p>Chargement...</p>}

            {fees && (
                <>
                    <ul className="nav nav-tabs">
                        {Object.keys(fees).map((type, index) => (
                            <li className="nav-item" key={index}>
                                <button
                                    className={`nav-link ${activeTab === type ? 'active activeTab' : 'inactiveTab'}`}
                                    onClick={() => handleTabClick(type)}
                                >
                                    {type}
                                </button>
                            </li>
                        ))}
                    </ul>

                    <div className="tab-content mt-3">
                        {Object.entries(fees).map(([type, subtypes]) => (
                            <div key={type} className={`tab-pane fade ${activeTab === type ? 'show active' : ''}`}>
                                {/* Render sub-tabs for Vendre */}
                                {type === "Vendre" && (
                                    <>

                                        <ul className="nav nav-tabs">
                                            <li className="nav-item">
                                                <button
                                                    className={`nav-link ${activeGenreTab === 'cashpoint_client' ? 'active activeTab' : 'inactiveTab'}`}
                                                    onClick={() => handleGenreClick('cashpoint_client')}
                                                >
                                                    Cash Point / Client
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className={`nav-link ${activeGenreTab === 'agentfpay_cashpoint' ? 'active activeTab' : 'inactiveTab'}`}
                                                    onClick={() => handleGenreClick('agentfpay_cashpoint')}
                                                >
                                                    Agent FPay / Cash Point
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className={`nav-link ${activeGenreTab === 'agentfpay_client' ? 'active activeTab' : 'inactiveTab'}`}
                                                    onClick={() => handleGenreClick('agentfpay_client')}
                                                >
                                                    Agent FPay / Client
                                                </button>
                                            </li>
                                        </ul>


                                        <ul className="nav nav-tabs mt-3">
                                            <li className="nav-item">
                                                <button
                                                    className={`nav-link ${activeSubTab === 'Madagascar' ? 'active activeTab' : 'inactiveTab'}`}
                                                    onClick={() => handleSubTabClick('Madagascar')}
                                                >
                                                    ARIARY
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className={`nav-link ${activeSubTab === 'International' ? 'active activeTab' : 'inactiveTab'}`}
                                                    onClick={() => handleSubTabClick('International')}
                                                >
                                                    EURO
                                                </button>
                                            </li>
                                        </ul>




                                        {/* Render the content based on active sub-tab */}
                                        {activeTab === type && (
                                            <>
                                                {activeSubTab === 'Madagascar' && (
                                                    // Render intervals for Madagascar
                                                    <>
                                                        {renderIntervals(Object.entries(activeGenre.ariary), 'ariary', 'Madagascar')}
                                                    </>
                                                )}
                                                {activeSubTab === 'International' && (
                                                    // Render intervals for International
                                                    <>
                                                        {renderIntervals(Object.entries(activeGenre.euro), 'euro', 'International')}
                                                    </>
                                                )}
                                            </>
                                        )}

                                    </>

                                )}

                                {/* Render the content based on active sub-tab */}
                                {activeTab === type && (
                                    <>
                                        {type === "Transferer" && (
                                            <>
                                                {renderIntervals2(Object.entries(fees.Transferer.default.ariary), type)}
                                            </>
                                        )}
                                        {type === "Payer" && (
                                            <>
                                                {renderIntervals2(Object.entries(fees.Payer.default.ariary), type)}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                </>
            )}

            {/* Modal for adding new interval */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-blue">AJOUTER PALIER</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center"><img src="/images/warning.png" alt=""/> Faites attention aux intervalles</p>
                    {/* Inputs for adding new interval */}
                    <div className="mb-3">
                        <label htmlFor="minInput" className="form-label">Min</label>
                        <input type="number" className="form-control" id="minInput" onChange={handleNewMinChange}
                               value={newInterval.min}/>
                    </div>
                    <div className="mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <label htmlFor="maxInput" className="form-label">Max</label>
                            <div className="form-check">
                                <input type="checkbox" id="infinityCheckbox" onChange={handleInfinityChange} className="form-check-input"/>
                                <label htmlFor="infinityCheckbox" className="form-check-label ms-1"> Ou plus</label>
                            </div>
                        </div>

                        <div className="input-group">
                            <input type="number" className="form-control" id="maxInput" onChange={handleNewMaxChange}
                                   value={newInterval.max}/>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="montantInput" className="form-label">Montant</label>
                        <input type="number" className="form-control" id="montantInput"
                               onChange={handleNewMontantChange} value={newInterval.amount}/>
                    </div>
                    <div className="d-flex justify-content-center">
                        <Button variant="success" onClick={handleSubmitNewInterval} className="me-5">Valider</Button>
                        <Button variant="secondary" onClick={handleCloseModal}>Annuler</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Fees;