import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Button} from 'react-bootstrap';

const Soldes = () => {
    const [walletBalances, setWalletBalances] = useState({});

    useEffect(() => {
        const fetchWalletBalances = async () => {
            try {
                const response = await fetch('https://wl.fpay.mg');
                const data = await response.json();
                setWalletBalances(data);
            } catch (error) {
                console.error('Error fetching wallet balances:', error);
            }
        };

        fetchWalletBalances();
    }, []);

    return (
        <div className="container mt-4">
            <div className="text-center my-4">
                <h2 className="myTitle">Solde FPay</h2>
            </div>
            <div className="d-flex justify-content-center">
                <div className="max-width-container">
                    <div className="mt-3">
                        <Row xs={1} md={2} className="g-4">
                            <Col>
                                <>
                                    <h3 className="titleSolde">AriaryCoin</h3>
                                    <Card>
                                        <Card.Body className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <Card.Title><span className="fw-bolder">ARC</span></Card.Title>
                                                <Card.Title>{walletBalances.ARC}</Card.Title>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </>
                            </Col>
                            <Col>
                                <>
                                    <h3 className="titleSolde">Espèces</h3>
                                    <Card>
                                        <Card.Body className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <Card.Title><span className="fw-bolder">Ariary</span></Card.Title>
                                                <Card.Title>{walletBalances.espece}</Card.Title>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </>
                            </Col>
                        </Row>
                        <h3 className="mt-3 titleSolde">Mobile Money</h3>
                        <Row xs={1} md={2} className="g-4">
                            <Col>
                                <Card>
                                    <Card.Body className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <Card.Title><span className="fw-bolder">Mvola 1</span></Card.Title>
                                            <Card.Title>{walletBalances["Mvola 1"]}</Card.Title>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Body className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <Card.Title><span className="fw-bolder">Mvola 2</span></Card.Title>
                                            <Card.Title>{walletBalances["Mvola 2"]}</Card.Title>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Body className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <Card.Title><span className="fw-bolder">Orange Money 1</span></Card.Title>
                                            <Card.Title>{walletBalances["Orange Money 1"]}</Card.Title>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Body className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <Card.Title><span className="fw-bolder">Orange Money 2</span></Card.Title>
                                            <Card.Title>{walletBalances["Orange Money 2"]}</Card.Title>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Body className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <Card.Title><span className="fw-bolder">Airtel Money 1</span></Card.Title>
                                            <Card.Title>{walletBalances["Airtel Money 1"]}</Card.Title>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Body className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <Card.Title><span className="fw-bolder">Airtel Money 2</span></Card.Title>
                                            <Card.Title>{walletBalances["Airtel Money 2"]}</Card.Title>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <h3 className="mt-3 titleSolde">Banques</h3>
                        <Row xs={1} md={2} className="g-4">
                            <Col>
                                <Card>
                                    <Card.Body className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <Card.Title><span
                                                className="fw-bolder">Compte Bancaire International</span></Card.Title>
                                            <Card.Title>{walletBalances["banque 1"]}</Card.Title>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Body className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <Card.Title><span
                                                className="fw-bolder">Compte Bancaire Madagascar</span></Card.Title>
                                            <Card.Title>{walletBalances["banque 2"]}</Card.Title>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Soldes;
