import React from 'react';
import {Button, Nav, Offcanvas} from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {getAuth, signOut} from 'firebase/auth';

const Sidebar = ({show, setShow, userName, userRole}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const auth = getAuth();

    const isLinkActive = (path) => location.pathname === path;

    const handleLogout = async () => {
        try {
            await signOut(auth);
            if (userRole === 'client' || userRole === undefined) {
                window.location.href = 'https://fpay.mg/';
            } else {
                navigate('/login');
            }
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleLinkClick = () => {
        // Close the sidebar when a link is clicked
        setShow(false);
    };

    // Sidebar content
    const adminSidebarContent = (
        <Nav className="flex-column mt-3">
            <Link to="/" className="w-100 mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-pie-chart me-2"></i>Dashboard
                </Button>
            </Link>
            <Link to="/compte" className="w-100 mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/compte") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-person me-2"></i>Mon Compte
                </Button>
            </Link>
            <Link to="/cours" className="w-100 mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/cours") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-currency-exchange me-2"></i>Cours
                </Button>
            </Link>
            <Link to="/utilisateurs" className="w-100 mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/utilisateurs") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-person me-2"></i>Utilisateurs
                </Button>
            </Link>
            <Link to="/frais" className="w-100  mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/frais") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-cash-coin me-2"></i>Frais
                </Button>
            </Link>
            <Link to="/validation" className="w-100  mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/validation") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-cash me-2"></i>Virement
                </Button>
            </Link>
            <Link to="/historique" className="w-100  mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/historique") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-clock-history me-2"></i>Historiques
                </Button>
            </Link>
            <Button variant="outline-danger" className="mt-3 mb-4" onClick={handleLogout}>
                <i className="bi bi-box-arrow-right me-2"></i>Se déconnecter
            </Button>
        </Nav>
    );

    const agentSidebarContent = (
        <Nav className="flex-column mt-3">
            <Link to="/agent" className="w-100 mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/agent") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-pie-chart me-2"></i>En Attente
                </Button>
            </Link>
            <Link to="/virements" className="w-100  mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/virements") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-bank2 me-2"></i>Virements
                </Button>
            </Link>
            <Link to="/vouchers" className="w-100  mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/vouchers") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-qr-code me-2"></i>Vouchers
                </Button>
            </Link>
            <Link to="/historique" className="w-100  mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/historique") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-clock-history me-2"></i>Historiques
                </Button>
            </Link>

            <Button variant="outline-danger" className="mt-3" onClick={handleLogout}>
                <i className="bi bi-box-arrow-right me-2"></i>Se déconnecter
            </Button>
        </Nav>
    );

    const marchandSidebarContent = (
        <Nav className="flex-column mt-3">
            <Link to="/marchand" className="w-100 mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/marchand") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-pie-chart me-2"></i>Dashboard
                </Button>
            </Link>
            <Link to="/compte" className="w-100 mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/compte") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-person me-2"></i>Mon Compte
                </Button>
            </Link>
            <Link to="/vouchers/generate" className="w-100  mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/vouchers/generate") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-qr-code me-2"></i>Création Transaction
                </Button>
            </Link>
            <Link to="/vouchers" className="w-100  mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/vouchers") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-clock-history me-2"></i>Historiques
                </Button>
            </Link>

            <Button variant="outline-danger" className="mt-3" onClick={handleLogout}>
                <i className="bi bi-box-arrow-right me-2"></i>Se déconnecter
            </Button>
        </Nav>
    );

    const clientSidebarContent = (
        <Nav className="flex-column mt-3">
            <Link to="/marchand" className="w-100 mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/marchand") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-pie-chart me-2"></i>Dashboard
                </Button>
            </Link>
            <Link to="/compte" className="w-100 mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/compte") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-person me-2"></i>Mon Compte
                </Button>
            </Link>
            <Link to="/ARC/acheter" className="w-100  mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/ARC/acheter") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-cash-coin me-2"></i>Acheter ARC
                </Button>
            </Link>
            <Link to="/ARC/vendre" className="w-100  mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/ARC/vendre") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-cash-coin me-2"></i>Vendre ARC
                </Button>
            </Link>
            <Link to="/ARC/payer" className="w-100  mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/ARC/payer") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-cash-coin me-2"></i>Payer ARC
                </Button>
            </Link>
            <Link to="/ARC/transferer" className="w-100  mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/ARC/transferer") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-cash-coin me-2"></i>Transferer ARC
                </Button>
            </Link>
            <Link to="/client/historique" className="w-100  mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/client/historique") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-clock-history me-2"></i>Historique
                </Button>
            </Link>

            <Button variant="outline-danger" className="mt-3" onClick={handleLogout}>
                <i className="bi bi-box-arrow-right me-2"></i>Se déconnecter
            </Button>
        </Nav>
    );

    const cashpointSidebarContent = (
        <Nav className="flex-column mt-3">
            <Link to="/marchand" className="w-100 mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/marchand") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-pie-chart me-2"></i>Dashboard
                </Button>
            </Link>
            <Link to="/compte" className="w-100 mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/compte") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-person me-2"></i>Mon Compte
                </Button>
            </Link>
            <Link to="/ARC/acheter" className="w-100  mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/ARC/acheter") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-cash-coin me-2"></i>Acheter ARC
                </Button>
            </Link>
            <Link to="/ARC/vendre" className="w-100  mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/ARC/vendre") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-cash-coin me-2"></i>Vendre ARC
                </Button>
            </Link>
            <Link to="/vouchers" className="w-100  mb-4" onClick={handleLinkClick}>
                <Button variant={isLinkActive("/vouchers") ? "primary" : "outline-primary"} className="w-100">
                    <i className="bi bi-clock-history me-2"></i>Historiques
                </Button>
            </Link>

            <Button variant="outline-danger" className="mt-3" onClick={handleLogout}>
                <i className="bi bi-box-arrow-right me-2"></i>Se déconnecter
            </Button>
        </Nav>
    );

    // Determine which sidebar content to display based on the user role
    let sidebarContent;
    switch (userRole) {
        case 'admin':
            sidebarContent = adminSidebarContent;
            break;
        case 'agent':
            sidebarContent = agentSidebarContent;
            break;
        case 'marchand':
            sidebarContent = marchandSidebarContent;
            break;
        case 'cashpoint':
            sidebarContent = cashpointSidebarContent;
            break;
        case 'client':
        case undefined:
            sidebarContent = clientSidebarContent;
            break;
        default:
            // Default sidebar content if user role is not recognized
            sidebarContent = <div>No Sidebar Content Available</div>;
    }

    return (
        <>
            {/* Navbar for small screens */}

            <Offcanvas show={show} onHide={handleClose} placement="start">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{userName} ({userRole})</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {sidebarContent}
                </Offcanvas.Body>
            </Offcanvas>

            {/* Regular Sidebar for larger screens */}
            <div className="d-none d-md-block bg-light p-3" style={{width: '280px', height: '100vh'}}>
                <a href="/"
                   className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
                    <span className="mx-auto text-center fs-4">
                        <img src="/images/banner.png" alt="FPay" width={200}/>
                    </span>
                </a>
                <p className="d-flex align-items-center mt-3 mb-md-0 me-md-auto text-decoration-none">
                    <span className="mx-auto text-center">{userName}</span>
                </p>
                <hr id="hrSidebar"/>
                <p className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
                    <span
                        className="mx-auto text-center fs-5">{userRole? userRole.replace('admin', 'administrateur').toUpperCase(): 'CLIENT'}</span>
                </p>
                {sidebarContent}
            </div>
        </>
    );
};

export default Sidebar;