import React, { useState } from 'react';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import countries from '../countries-FR.json';

const NewAgent = ({ admin }) => {
    const [username, setUsername] = useState('');
    const [firstName, setfirstName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [entreprise, setEntreprise] = useState('');
    const [role, setRole] = useState(''); // State for password visibility toggle
    const [showPassword, setShowPassword] = useState(false); // State for password visibility toggle
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State for confirm password visibility toggle
    const [errorMessage, setErrorMessage] = useState('');
    const [country, setCountry] = useState('');
    const auth = getAuth();
    const navigate = useNavigate();

    const traduireMessageErreur = (errorCode, message) => {
        switch (errorCode) {
            case 'auth/email-already-in-use':
                return "Cette adresse email est déjà utilisée par un autre compte.";
            case 'auth/invalid-email':
                return "L'adresse email fournie est invalide.";
            case 'auth/operation-not-allowed':
                return "La création de compte est actuellement désactivée.";
            case 'auth/weak-password':
                return "Le mot de passe fourni est trop faible.";
            default:
                return "Erreur lors de la création du compte. Veuillez réessayer." + message;
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (auth.currentUser) {
                if (password !== confirmPassword) {
                    throw new Error("Les mots de passe ne correspondent pas");
                }

                const idToken = await auth.currentUser.getIdToken(true);

                const url = admin ? `${process.env.REACT_APP_API_URL}/users/signup/admin` : `${process.env.REACT_APP_API_URL}/users/signup/agent`;

                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${idToken}`
                    },
                    body: JSON.stringify({
                        username,
                        firstName,
                        email,
                        password,
                        phoneNumber,
                        entreprise,
                        role,
                        country
                    })
                });

                if (response.ok) {
                    navigate('/utilisateurs');
                } else {
                    const errorResponse = await response.json();
                    throw new Error('Failed to create new account: ' + errorResponse.error);
                }
            } else {
                throw new Error('No authenticated user found. Please log in.');
            }
        } catch (error) {
            console.error(error);
            setErrorMessage('Erreur: ' + error.message);
        }
    };

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="card-title fw-bold text-center">Information du compte</h3>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group mb-3">
                                    <label htmlFor="username">Nom</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="username"
                                        placeholder="Votre nom"
                                        value={username}
                                        onChange={e => setUsername(e.target.value)}
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="username">Prénom</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="firstname"
                                        placeholder="Votre prénom"
                                        value={firstName}
                                        onChange={e => setfirstName(e.target.value)}
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="username">Entreprise</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="entreprise"
                                        placeholder="Votre entreprise"
                                        value={entreprise}
                                        onChange={e => setEntreprise(e.target.value)}
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="username">Pays</label>
                                    <select className="form-control" id="country" value={country} onChange={e => setCountry(e.target.value)}>
                                        <option value="">Choisir le pays...</option>
                                        {Object.entries(countries).map(([code, name]) => (
                                            <option key={code} value={code}>{name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="role">Type de compte</label>
                                    <div className="input-group">
                                        <select
                                            className="form-select"
                                            id="role"
                                            value={role}
                                            onChange={e => setRole(e.target.value)}
                                        >
                                            <option value="">Choisir le type de compte...</option>
                                            <option value="admin">Administrateur</option>
                                            <option value="user">Utilisateur</option>
                                            <option value="agent">Agent comptable</option>
                                            <option value="fpay">Agent FPay</option>
                                            <option value="agent">Agent</option>
                                            <option value="marchand">Marchand</option>
                                            <option value="cashpoint">Cash Point</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group mb-3">
                                    <label htmlFor="username">Téléphone</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="phoneNumber"
                                        placeholder="Votre numéro de téléphone"
                                        value={phoneNumber}
                                        onChange={e => setPhoneNumber(e.target.value)}
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="email">Adresse Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        value={email}
                                        placeholder="Votre adresse email"
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="password">Mot de passe</label>
                                    <div className="input-group">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            className="form-control"
                                            id="password"
                                            placeholder="Mot de passe"
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                        />
                                        <button
                                            className="btn btn-outline-secondary"
                                            type="button"
                                            onClick={() => setShowPassword(prevState => !prevState)}
                                        >
                                            {showPassword ? (
                                                <i className="bi bi-eye-slash"></i>
                                            ) : (
                                                <i className="bi bi-eye"></i>
                                            )}
                                        </button>
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="confirmPassword">Confirmez le mot de passe</label>
                                    <div className="input-group">
                                        <input
                                            type={showConfirmPassword ? "text" : "password"}
                                            className="form-control"
                                            id="confirmPassword"
                                            placeholder="Confirmez le mot de passe"
                                            value={confirmPassword}
                                            onChange={e => setConfirmPassword(e.target.value)}
                                        />
                                        <button
                                            className="btn btn-outline-secondary"
                                            type="button"
                                            onClick={() => setShowConfirmPassword(prevState => !prevState)}
                                        >
                                            {showConfirmPassword ? (
                                                <i className="bi bi-eye-slash"></i>
                                            ) : (
                                                <i className="bi bi-eye"></i>
                                            )}
                                        </button>
                                    </div>
                                </div>
                                {errorMessage && <Alert variant="danger" className="mt-3">{errorMessage}</Alert>}
                                <button type="submit" className="btn btn-primary w-100">Créer</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewAgent;
