import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {getAuth} from 'firebase/auth';
import {Alert} from "react-bootstrap";
import countries from '../../countries-FR.json';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [entreprise, setEntreprise] = useState('');
    const [country, setCountry] = useState('');
    const [role, setRole] = useState('');
    const [phone, setPhone] = useState('');
    const navigate = useNavigate();
    const auth = getAuth();

    const traduireMessageErreur = (errorCode) => {
        switch (errorCode) {
            case 'auth/invalid-credential':
                return "Adresse Email ou Mot de Passe erroné";
            case 'auth/invalid-email':
                return "L'adresse email fournie est invalide.";
            case 'auth/user-disabled':
                return "Ce compte utilisateur a été désactivé.";
            case 'auth/user-not-found':
                return "Aucun utilisateur trouvé avec cet email.";
            case 'auth/wrong-password':
                return "Le mot de passe est incorrect.";
            case 'auth/invalid-password':
                return "Votre mot de passe doit comporter plus de 6 caractères";
            default:
                // console.log(errorCode);
                // console.log(errorMessage);
                return "Erreur de connexion. Veuillez réessayer.";
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if (password !== confirmPassword) {
                throw new Error("Les mots de passe ne correspondent pas");
            }

            const url = `${process.env.REACT_APP_API_URL}/users/signupClient`;

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username : nom,
                    firstName: prenom,
                    email,
                    password,
                    phoneNumber: phone,
                    entreprise,
                    role,
                    country
                })
            });

            if (response.ok) {
                navigate('/client/login');
            } else {
                const errorResponse = await response.json();
                setErrorMessage(traduireMessageErreur(errorResponse.error));
            }
        } catch (error) {
            setErrorMessage(traduireMessageErreur(error));
        }
    };

    const handleCancel = async (e) => {
        e.preventDefault();
        navigate('/client/login');
    };

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-center mt-5">
                                <img src="/images/banner.png" alt="FPay" width={252}/>
                            </div>
                            <h1 className="text-center my-3 fw-bold">Inscription</h1>
                            <form className="mx-3">
                                <div className="mb-4">
                                    <label htmlFor="nom" className="form-label">Nom</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="nom"
                                        required
                                        value={nom}
                                        onChange={e => setNom(e.target.value)}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="prenom" className="form-label">Prénom</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="prenom"
                                        required
                                        value={prenom}
                                        onChange={e => setPrenom(e.target.value)}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="entreprise" className="form-label">Entreprise</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="entreprise"
                                        required
                                        value={entreprise}
                                        onChange={e => setEntreprise(e.target.value)}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="country" className="form-label">Pays</label>
                                    <select className="form-control" id="country" value={country}
                                            onChange={e => setCountry(e.target.value)}>
                                        <option value="">Choisir le pays...</option>
                                        {Object.entries(countries).map(([code, name]) => (
                                            <option key={code} value={code}>{name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="role" className="form-label">Type de compte</label>
                                    <select className="form-control" id="role" value={role}
                                            onChange={e => setRole(e.target.value)}>
                                        <option value="">Choisir le type de compte...</option>
                                        <option value="client">Client</option>
                                        <option value="cashpoint">Cash Point</option>
                                        <option value="marchand">Marchand</option>
                                    </select>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="tel" className="form-label">Téléphone</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="tel"
                                        required
                                        value={phone}
                                        onChange={e => setPhone(e.target.value)}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        required
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="password" className="form-label">Mot de passe</label>
                                    <div className="input-group">
                                        <input
                                            type={showPassword ? "text" : "password"} // Use state to determine input type
                                            className="form-control"
                                            id="password"
                                            autoComplete="current-password"
                                            required
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary"
                                            onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                                        >
                                            {showPassword ? <i className="bi bi-eye-slash"></i> :
                                                <i className="bi bi-eye"></i>} {/* Show eye icon based on password visibility */}
                                        </button>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="confirmPassword" className="form-label">Confirmez le Mot de
                                        Passe</label>
                                    <div className="input-group">
                                        <input
                                            type={showConfirmPassword ? "text" : "password"}
                                            className="form-control"
                                            id="confirmPassword"
                                            autoComplete="new-password"
                                            required
                                            value={confirmPassword}
                                            onChange={e => setConfirmPassword(e.target.value)}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary"
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                        >
                                            {showConfirmPassword ? <i className="bi bi-eye-slash"></i> :
                                                <i className="bi bi-eye"></i>}
                                        </button>
                                    </div>
                                </div>
                                {errorMessage && <Alert variant="danger" className="mt-3">{errorMessage}</Alert>}
                                <div className="d-flex justify-content-center my-5">
                                    <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Créer
                                    </button>
                                    <button type="submit" className="btn btn-secondary ms-5"
                                            onClick={handleCancel}>Annuler
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;