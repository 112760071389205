import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {Card, Button, Modal} from 'react-bootstrap';
import {getAuth} from 'firebase/auth';

const TransactionDetails = () => {
    const location = useLocation();
    const {transactionId, userID} = location.state;
    const [transactionDetails, setTransactionDetails] = useState(null);
    const [bankAccountLines, setBankAccountLinesData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [disableUpdate, setDisableUpdate] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const auth = getAuth();

    const fetchTransactionDetailsFromAPI = async () => {
        try {
            // Get Firebase authentication token
            const idToken = await auth.currentUser.getIdToken(true);
            const url = `${process.env.REACT_APP_API_URL}/transactions/${transactionId}`;

            // Fetch transaction details from the backend
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                }
            });

            // Parse response as JSON
            const data = await response.json();

            if (response.ok) {
                setTransactionDetails(data);
                if (data.ref && data.ref !== '') {
                    const encodedRef = encodeURIComponent(data.ref);
                    const apiUrl = `https://compta.fanampiana.com/api/index.php/bankaccounts/8/lines?sqlfilters=(label:like:'${encodedRef}%25')`;

                    const bankAccountLinesResponse = await fetch(apiUrl, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'DOLAPIKEY': process.env.REACT_APP_DOLIBARR_API_TOKEN
                        }
                    });

                    const bankAccountLinesData = await bankAccountLinesResponse.json();
                    if (bankAccountLinesResponse.ok) {
                        setBankAccountLinesData(bankAccountLinesData);
                        // console.log('Bank account lines data:', bankAccountLinesData);
                    } else {
                        console.log('No data available');
                    }
                }
            } else {
                console.log('No data available');
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTransactionDetailsFromAPI();
    }, [transactionId, userID]);

    const handleAction = async (action, email, transactionID) => {
        setSelectedTransaction({action, email, transactionID});
        setShowConfirmModal(true);
    };

    const confirmAction = async () => {
        const {action, email, transactionID} = selectedTransaction;
        const endpoint = action === 'validate' ? '/transactions/validate' : '/transactions/refuse';

        try {
            setDisableUpdate(true);

            const idToken = await auth.currentUser.getIdToken(true);
            const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}`},
                body: JSON.stringify({email, transactionID}),
            });
            const data = await response.json();
            if (data.success) {
                console.log(`Transaction ${action}d successfully.`);
                fetchTransactionDetailsFromAPI();
            } else {
                console.error(data.error || `Failed to ${action} transaction.`);
            }
        } catch (error) {
            console.error(`Error ${action}ing transaction:`, error);
        } finally {
            setDisableUpdate(false);
            setShowConfirmModal(false);
        }
    };

    if (loading) {
        return <div>Chargement des détails...</div>;
    }

    if (!transactionDetails) {
        return <div>Aucun détail de transaction disponible.</div>;
    }

    return (
        <>
            <Card className="m-3">
                <Card.Header>Détails de la Transaction {transactionId}</Card.Header>
                <Card.Body>
                    <p><b>Date:</b> {new Date(transactionDetails.timestamp).toLocaleString()}</p>
                    <p><b>Email de l'utilisateur:</b> {transactionDetails.user_email}</p>
                    <p><b>Nom:</b> {transactionDetails.user.name} ({transactionDetails.user.display_name})</p>
                    <p><b>Téléphone:</b> {transactionDetails.user.phone}</p>
                    <p><b>Montant:</b> {transactionDetails.amount} €</p>
                    <p><b>Transaction:</b> {transactionDetails.transaction_type}</p>
                    <p><b>Status:</b> {transactionDetails.state}
                        {(transactionDetails.state === 'validé' || transactionDetails.state === 'refusé') ? ` par ${transactionDetails.adminEmail}` : ''}
                        {transactionDetails.state === 'validé' && ` le ${new Date(transactionDetails.validationDate).toLocaleString()}`}
                        {transactionDetails.state === 'refusé' && ` le ${new Date(transactionDetails.refusalDate).toLocaleString()}`}
                    </p>
                    {transactionDetails.ref && transactionDetails.ref !== '' && bankAccountLines.length === 0 && (
                        <div>
                            <p><b>Détails du virement:</b> En attente</p>
                            <ul>
                                <li>En attente</li>
                                <li>Ref: {transactionDetails.ref}</li>
                            </ul>
                        </div>
                    )}
                    {transactionDetails.ref && transactionDetails.ref !== '' && bankAccountLines.length > 0 && (
                        <div>
                            <p><b>Détails du virement:</b></p>
                            <ul>
                                <li>Ref: {transactionDetails.ref}</li>
                                <li>Banque: {bankAccountLines[0].bank_account_label}</li>
                                <li>Montant: {bankAccountLines[0].amount}</li>
                                <li>Date: {bankAccountLines[0].num_releve}</li>
                            </ul>
                        </div>
                    )}
                </Card.Body>
                <Card.Footer className="d-flex justify-content-between">
                    {transactionDetails.state === 'en attente' && (
                        <div>
                            <Button
                                variant="success"
                                onClick={() => handleAction('validate', transactionDetails.user_email, transactionId)}
                                disabled={disableUpdate}
                            >
                                Valider
                            </Button>
                            {' '}
                            <Button
                                variant="outline-danger"
                                onClick={() => handleAction('refuse', transactionDetails.user_email, transactionId)}
                                disabled={disableUpdate}
                            >
                                Refuser
                            </Button>
                        </div>
                    )}
                    <Button variant="primary" onClick={() => window.history.back()}>Retour</Button>
                </Card.Footer>
            </Card>

            {/* Confirm Modal */}
            <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Voulez-vous
                    vraiment {selectedTransaction?.action === 'validate' ? 'valider' : 'refuser'} cette
                    transaction?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>Annuler</Button>
                    <Button variant="primary" onClick={confirmAction}>Confirmer</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TransactionDetails;
