import React, { useState } from 'react';
import Soldes from "../components/Soldes";
import DailyTransactions from "../components/DailyTransactions";

const Dashboard = () => {
    return (
        <>
            <h1 className="text-center mt-5 text-blue">DASHBOARD</h1>
            <Soldes />
            <DailyTransactions/>
        </>
    );
}

export default Dashboard;