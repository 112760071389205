import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, Modal, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import Spinner from "react-bootstrap/Spinner";

const AcheterARC = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false); // State to manage modal visibility
    const [showConfirmModal, setShowConfirmModal] = useState(false); // State to manage confirmation modal visibility
    const [confirmAction, setConfirmAction] = useState(''); // State to manage the action triggering the confirmation modal
    const [userEmail, setUserEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [fees, setFees] = useState(null);
    const [loading, setLoading] = useState(true);
    const [payerFee, setPayerFee] = useState({});
    const [fee, setFee] = useState(0);
    const [operateur, setOperateur] = useState('airtel');
    const [solde, setSolde] = useState(0);
    const [showQrModal, setShowQrModal] = useState(false); // State to manage QR modal visibility
    const [qrCode, setQrCode] = useState(''); // State for QR code input
    const auth = getAuth();

    const [formValues, setFormValues] = useState({
        modePaiement: 'compte', montant: 1
    }); // State to store form values

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setUserEmail(user.email);
            } else {
                setUserEmail('');
            }
        });

        return () => unsubscribe();
    }, []);

    const fetchFees = async () => {
        setLoading(true);
        try {
            const idToken = await auth.currentUser.getIdToken(true);

            const response = await fetch(`${process.env.REACT_APP_API_URL}/transactions/fees`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch fees');
            }
            const data = await response.json();

            console.log("data", data);

            // Set the fetched fees data to state
            setFees(data);
            setPayerFee(data.Payer.default.ariary);
        } catch (error) {
            console.error('Error fetching fees:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchSolde = async () => {
        setLoading(true);
        try {
            // Get the ID token of the current user
            const idToken = await auth.currentUser.getIdToken(true);

            // Get the UID of the current user
            const uid = auth.currentUser.uid;

            // Make the API request with the ID token and UID
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${uid}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch solde');
            }

            const data = await response.json();

            setSolde(data.solde);
        } catch (error) {
            console.error('Error fetching fees:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFees();
        fetchSolde();
    }, []);

    const handleScan = (e) => {
        e.preventDefault();
        setShowQrModal(true); // Show the QR code modal
    };

    const handleQrSubmit = async (e) => {
        e.preventDefault();
        setShowQrModal(false); // Hide the QR modal
        setConfirmAction('scan');
        setShowConfirmModal(true); // Show the confirmation modal
    };

    const handleMobile = (e) => {
        e.preventDefault();
        setConfirmAction('mobile');
        setShowConfirmModal(true); // Show the confirmation modal
    };

    const handleConfirm = async () => {
        setShowConfirmModal(false);
        try {
            const idToken = await auth.currentUser.getIdToken(true);

            const response = await fetch(`${process.env.REACT_APP_API_URL}/transactions/transfererARC`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({
                    senderEmail: userEmail,
                    recipientEmail: confirmAction === 'scan' ? qrCode : formValues.email,
                    amount: formValues.montant,
                    fee: fee
                })
            });

            const data = await response.json();
            if (response.ok) {
                setErrorMessage('');
                // Handle success (e.g., navigate to a success page or show a success message)
                console.log('Voucher used successfully:', data);
                fetchSolde();
            } else {
                setErrorMessage(data.error || 'Failed to transfer ARC');
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('Failed to transfer ARC');
        }
    };

    // Function to handle input changes and update formValues state
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });

        if (name === 'montant') {
            let frais = 0;
            frais = formValues.modePaiement !== 'mobile' ?
                findFee(payerFee, formValues.modePaiement, '', '', value) :
                findFee(payerFee, operateur, '', '', value);
            setFee(value - parseFloat(frais));
        }
    };

    function findFee(clientData, modePaiement, prefix, postfix, amount) {
        const modePaiementKey = `${prefix}${modePaiement}${postfix}`;
        const intervals = clientData[modePaiementKey] || [];

        return intervals.reduce((acc, interval) => {
            if (amount >= interval.min && amount <= interval.max) {
                return interval.amount;
            }
            return acc;
        }, 0);
    }

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner animation="border" />
            </div>
        );
    }

    return (
        <div className="container mt-5">
            <h1 className="text-center mt-5 text-blue">PAYER ARIARYCOIN</h1>
            <p className="text-center">Votre Solde : {solde.toLocaleString()} ARC</p>

            <Form
                className="mt-5"
                style={{ maxWidth: '456px', marginLeft: '100px' }}
            >
                <Row className="mb-3">
                    <Col xs={5} className="d-flex align-items-center">
                        <Form.Label className="mb-0">Choisir Mode de Paiement :</Form.Label>
                    </Col>
                    <Col xs={7}>
                        <Form.Select
                            name="modePaiement"
                            aria-label="Sélectionnez votre réponse"
                            value={formValues.modePaiement}
                            onChange={handleInputChange}
                        >
                            <option value="compte">FPAY</option>
                            <option value="mobile">Mobile Money</option>
                        </Form.Select>
                    </Col>
                </Row>

                {formValues.modePaiement === 'mobile' &&
                    <Row className="mb-3">
                        <Col xs={5} className="d-flex align-items-center">
                            <Form.Label className="mb-0">Choisir Opérateur :</Form.Label>
                        </Col>
                        <Col xs={7}>
                            <Form.Select
                                name="modePaiement"
                                aria-label="Sélectionnez votre réponse"
                                value={operateur}
                                onChange={(e) => setOperateur(e.target.value)}
                            >
                                <option value="airtel">Airtel Money</option>
                                <option value="telma">Mvola</option>
                                <option value="orange">Orange Money</option>
                            </Form.Select>
                        </Col>
                    </Row>
                }

                <Row className="mb-3">
                    <Col xs={5} className="d-flex align-items-center">
                        <Form.Label className="mb-0">Entrer montant :</Form.Label>
                    </Col>
                    <Col xs={7}>
                        <Form.Control
                            type="number"
                            placeholder="Entrez le montant"
                            name="montant"
                            value={formValues.montant}
                            onChange={handleInputChange}
                            min={1000}
                        />
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col xs={5} className="d-flex align-items-center">
                        <Form.Label className="mb-0">MONTANT ARC</Form.Label>
                    </Col>
                    <Col xs={7}>
                        <Form.Control
                            type="number"
                            placeholder="Entrez le montant"
                            name="montant"
                            value={fee}
                            min={1}
                            disabled
                        />
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col xs={5} className="d-flex align-items-center"></Col>
                    <Col xs={7}>
                        {formValues.modePaiement === 'compte' &&
                            <Row className="justify-content-center mb-3">
                                <Col xs="auto">
                                    {errorMessage && <Alert className="my-2" variant="danger">{errorMessage}</Alert>}
                                    <Button variant="primary" type="submit" onClick={handleScan}>
                                        Scanner QR code
                                    </Button>
                                </Col>
                            </Row>
                        }
                        {formValues.modePaiement !== 'compte' &&
                            <Row className="justify-content-center mb-3">
                                <Col xs="auto">
                                    {errorMessage && <Alert className="my-2" variant="danger">{errorMessage}</Alert>}
                                    <Button variant="success" type="submit" onClick={handleMobile}>
                                        Valider
                                    </Button>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            </Form>
            {/* Modal for QR code input */}
            <Modal show={showQrModal} onHide={() => setShowQrModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Scanner QR code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleQrSubmit}>
                        <Form.Group controlId="formQrCode">
                            <Form.Label>Entrez le code QR</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Code QR"
                                value={qrCode}
                                onChange={(e) => setQrCode(e.target.value)}
                            />
                        </Form.Group>
                        {errorMessage && <Alert className="my-2" variant="danger">{errorMessage}</Alert>}
                        <Button variant="primary" type="submit" className="mt-3">
                            Valider
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
            {/* Confirmation modal */}
            <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmer le transfert</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Voulez-vous vraiment transférer {formValues.montant} ARC à {confirmAction === 'scan' ? qrCode : "l'opérateur mobile"} ?</p>
                    <p>Frais de transaction : {formValues.montant - fee} ARC</p>
                    <Button variant="danger" onClick={() => setShowConfirmModal(false)}>
                        Annuler
                    </Button>
                    <Button variant="success" onClick={handleConfirm} className="ms-2">
                        Confirmer
                    </Button>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AcheterARC;