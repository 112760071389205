import React, {useState, useEffect} from 'react';
import {Row, Col, Form, Button, Modal} from 'react-bootstrap';
import {useLocation} from 'react-router-dom'; // Import useLocation from react-router-dom

const AcheterARC = () => {
    const location = useLocation(); // Initialize useLocation hook
    const [transactionData, setTransactionData] = useState(null); // State to store transaction data

    useEffect(() => {
        // Check if location state contains transaction data
        if (location.state && location.state.formValues) {
            setTransactionData(location.state.formValues);
        }
    }, [location.state]); // Update state when location state changes

    return (
        <div className="container mt-5">
            <h1 className="text-center mt-5 text-blue">ACHETER ARIARYCOIN</h1>
            <p className="text-center">Votre Solde : X ARC</p>

            <h1 className="text-center mt-5 text-blue">Finalisation transaction</h1>
            {/* Display transaction data */}
            {transactionData && (
                <>
                    <p className="text-center">Votre transaction par {transactionData.modePaiement} est enregistrée
                        sous la référence :
                        .......</p>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div className="validationDiv">
                            <div>
                                <p>Pour finaliser la transaction,<br/>
                                    Veuillez faire le virement d'un montant de {transactionData.montant} EUROS en
                                    mettant en
                                    motif de virement : ....... sur le RIB suivant :
                                </p>
                                <p>IBAN : FR76 1695 8000 0137 0477 9026 607</p>
                                <p>BIC : QNTOFRP1XXX</p>
                                <p>Banque : Qonto</p>
                                <p>Motif de virement : (Reference de transaction)</p>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <p className="text-blue fw-bolder text-center mt-3">Merci pour votre confiance! <br/>
                L'équipe FPay</p>
        </div>
    );
};

export default AcheterARC;
