import React, {useState, useEffect} from 'react';
import {Row, Col, Form, Button, Modal, Alert} from 'react-bootstrap';
import {useLocation} from 'react-router-dom'; // Import useLocation from react-router-dom

const VendreARC = () => {
    const location = useLocation(); // Initialize useLocation hook
    const [transactionData, setTransactionData] = useState(null); // State to store transaction data
    const [iban, setIban] = useState('');
    const [bic, setBic] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        // Check if location state contains transaction data
        if (location.state && location.state.formValues) {
            setTransactionData(location.state.formValues);
        }
    }, [location.state]); // Update state when location state changes

    const handleSubmit = (e) => {
        e.preventDefault();
    };
    return (
        <div className="container mt-5">
            <h1 className="text-center mt-5 text-blue fw-bold">Vendre ARIARYCOIN</h1>
            <p className="text-center">Votre Solde : X ARC</p>

            <h1 className="text-center mt-5 text-blue fw-bold">Finalisation transaction</h1>
            {/* Display transaction data */}
            {transactionData && (
                <>
                    <p className="text-center">
                        Pour finaliser la transaction d’un montant de {transactionData.montant} EURO,
                    </p>
                    <p className="text-center"> Veuillez renseigner votre RIB pour le virement :</p>
                    <div className="mt-5" style={{display: 'flex', justifyContent: 'center'}}>
                        <div className="validationVendre">
                            <Form>
                                <Row className="mb-3">
                                    <Col xs={3} className="d-flex align-items-center">
                                        <Form.Label className="mb-0"><strong>IBAN</strong>: </Form.Label>
                                    </Col>
                                    <Col xs={9}>
                                        <Form.Control
                                            type="text"
                                            name="iban"
                                            value={iban}
                                            onChange={(e) => setIban(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col xs={3} className="d-flex align-items-center">
                                        <Form.Label className="mb-0"><strong>BIC</strong>: </Form.Label>
                                    </Col>
                                    <Col xs={9}>
                                        <Form.Control
                                            type="text"
                                            name="bic"
                                            value={bic}
                                            onChange={(e) => setBic(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                    <p className="text-blue fw-bolder text-center mt-5">Merci pour votre confiance! <br/>
                        L'équipe FPay</p>
                    <Row className="justify-content-center mt-5">
                        <Col xs="auto">
                            {errorMessage && <Alert className="my-2" variant="danger">{errorMessage}</Alert>}
                            <Button variant="success" type="submit" onClick={handleSubmit}>
                                Valider
                            </Button>
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
};

export default VendreARC;
