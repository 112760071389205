import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Alert, Modal } from 'react-bootstrap';
import { getAuth } from 'firebase/auth';
import PrintableVouchersModal from '../components/PrintableVouchersModal';
import ListVouchers from '../components/ListVouchers';

const VoucherGenerator = () => {
    const [amount, setAmount] = useState('');
    const [numCodes, setNumCodes] = useState(1);
    const [commandNumber, setCommandNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [vouchers, setVouchers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setUserEmail(user.email);
            } else {
                setUserEmail('');
            }
        });

        return () => unsubscribe();
    }, []);

    const handleGenerateVoucher = async () => {
        try {
            setLoading(true);
            const response = await fetch(`${process.env.REACT_APP_APIV2}/generate_voucher`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: userEmail,
                    amount: parseFloat(amount),
                    num_codes: parseInt(numCodes)
                })
            });
            const data = await response.json();
            if (response.ok) {
                setSuccessMessage(data.message);
                setErrorMessage('');
                setVouchers(data.vouchers);
                setShowModal(true);
            } else {
                setErrorMessage(data.error);
                setSuccessMessage('');
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('Une erreur s\'est produite. Veuillez réessayer plus tard.');
            setSuccessMessage('');
        } finally {
            setLoading(false);
        }
    };

    const handlePrint = () => {
        window.print();
    };

    return (
        <div className="container mt-5">
            <h1 className="text-center my-5 text-blue">CREATION D’UNE TRANSACTION</h1>
            <Form>
                <FormGroup className="mb-3">
                    <Form.Label>Montant</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Entrez le montant"
                        min={1000}
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        style={{ maxWidth: '500px' }}
                    />
                </FormGroup>
                <FormGroup className="mb-3">
                    <Form.Label>Numéro de commande</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Entrez un numéro de commande"
                        value={commandNumber}
                        onChange={(e) => setCommandNumber(e.target.value)}
                        style={{ maxWidth: '500px' }}
                    />
                </FormGroup>
                <Button
                    variant="primary"
                    onClick={handleGenerateVoucher}
                    disabled={loading}
                >
                    {loading ? 'En cours...' : 'Générer un bon d’achat'}
                </Button>
                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
            </Form>

            <PrintableVouchersModal
                vouchers={vouchers}
                showModal={showModal}
                setShowModal={setShowModal}
            />
        </div>
    );
};

export default VoucherGenerator;
