import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Layout from "./components/Layout";
import Dashboard from "./views/Dashboard";
import DashboardMarchand from "./views/DashboardMarchand";
import Login from "./views/auth/Login";
import LoginHistory from "./views/LoginHistory";
import TransactionsHistory from "./components/TransactionsHistory";
import MyTransactionsHistory from "./components/MyTransactionsHistory";
import Agents from "./views/Agents";
import Admins from "./views/Admins";
import Compte from "./views/Compte";
import Users from "./views/Users";
import Vouchers from "./views/Vouchers";
import TransactionDetails from "./views/TransactionDetails";
import NewAgent from "./views/NewAgent";
import Virements from "./views/virements";
import Fees from "./views/fees";
import Cours from "./components/Cours";
import UserTabs from "./views/UserTabs";
import ListVouchers from "./components/ListVouchers";
import TransactionsTab from "./views/TransactionsTab";
import ClientLogin from "./views/auth/ClientLogin";
import ClientInscription from "./views/auth/ClientInscription";
import ClientDashboard from "./views/client/ClientDashboard";
import AcheterARC from "./views/client/AcheterARC";
import PayerARC from "./views/client/PayerARC";
import TransfererARC from "./views/client/TransfererARC";
import AcheterARCVirement from "./views/client/AcheterARCVirement";
import PayerARCMobile from "./views/client/PayerARCMobile";
import AcheterARCVoucher from "./views/client/AcheterARCVoucher";
import VendreARC from "./views/client/VendreARC";
import VendreARCVirement from "./views/client/VendreARCVirement";
import VendreARCMobile from "./views/client/VendreARCMobile";

function App() {
  return (
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Dashboard/>}/>
            <Route path="/marchand" element={<DashboardMarchand/>}/>
            <Route path="/cashpoint" element={<DashboardMarchand/>}/>
            <Route path="/historique" element={<TransactionsHistory/>}/>
            <Route path="/validation" element={<TransactionsTab validation={true}/>}/>
            <Route path="/transaction-details" element={<TransactionDetails/>}/>
            <Route path="/newAgent" element={<NewAgent/>}/>
            <Route path="/newAdmin" element={<NewAgent admin={true}/>}/>
            <Route path="/agent" element={<TransactionsHistory/>}/>
            <Route path="/compte" element={<Compte/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/virements" element={<Virements/>}/>
            <Route path="/frais" element={<Fees/>}/>
            <Route path="/vouchers/generate" element={<Vouchers/>}/>
            <Route path="/cours" element={<Cours/>}/>
            <Route path="/utilisateurs" element={<UserTabs/>}/>
            {/*<Route path="/utilisateurs" element={<Users/>}/>*/}
            {/*<Route path="/login-history" element={<LoginHistory/>}/>*/}
            {/*<Route path="/admins" element={<Admins/>}/>*/}
            {/*<Route path="/agents" element={<Agents/>}/>*/}
            <Route path="/vouchers" element={<ListVouchers/>}/>

            {/*CLIENTS ROUTES*/}
            <Route path="/client/login" element={<ClientLogin/>}/>
            <Route path="/client/inscription" element={<ClientInscription/>}/>
            <Route path="/client/dashboard" element={<ClientDashboard/>}/>
            <Route path="/client/historique" element={<MyTransactionsHistory/>}/>
            <Route path="/ARC/acheter" element={<AcheterARC/>}/>
            <Route path="/ARC/acheter/virement" element={<AcheterARCVirement/>}/>
            <Route path="/ARC/acheter/voucher" element={<AcheterARCVoucher/>}/>

            <Route path="/ARC/payer" element={<PayerARC/>}/>
            <Route path="/ARC/payer/mobile" element={<PayerARCMobile/>}/>

            <Route path="/ARC/transferer" element={<TransfererARC/>}/>
            <Route path="/ARC/vendre" element={<VendreARC/>}/>
            <Route path="/ARC/vendre/virement" element={<VendreARCVirement/>}/>
            <Route path="/ARC/vendre/mobile" element={<VendreARCMobile/>}/>
          </Routes>
        </Layout>
      </BrowserRouter>
  );
}

export default App;
