import React, {useState, useEffect} from 'react';
import {Row, Col, Form, Button, Modal, Alert} from 'react-bootstrap';
import {useLocation} from 'react-router-dom'; // Import useLocation from react-router-dom

const AcheterARC = () => {
    const location = useLocation(); // Initialize useLocation hook
    const [transactionData, setTransactionData] = useState(null); // State to store transaction data
    const [errorMessage, setErrorMessage] = useState('');
    const [numero, setNumero] = useState('');

    useEffect(() => {
        // Check if location state contains transaction data
        if (location.state && location.state.formValues) {
            setTransactionData(location.state.formValues);
        }
    }, [location.state]); // Update state when location state changes

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <div className="container mt-5">
            <h1 className="text-center mt-5 text-blue">PAYER ARIARYCOIN</h1>
            <p className="text-center">Votre Solde : X ARC</p>
            <Form
                className="mt-5"
                style={{maxWidth: '500px', marginLeft: '100px'}}
            >
                <Row className="mb-3">
                    <Col xs={5} className="d-flex align-items-center">
                        <Form.Label className="mb-0">Entrer Numéro Mobile Money : </Form.Label>
                    </Col>
                    <Col xs={7}>
                        <Form.Control
                            type="text"
                            name="numero"
                            value={numero}
                            onChange={(e) => setNumero(e.target.value)}
                        />
                    </Col>
                </Row>

                <Row className="justify-content-center mt-5">
                    <Col xs="auto">
                        {errorMessage && <Alert className="my-2" variant="danger">{errorMessage}</Alert>}
                        <Button variant="success" type="submit" onClick={handleSubmit}>
                            Valider
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default AcheterARC;
