import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import "react-datepicker/dist/react-datepicker.css";
import { getAuth } from "firebase/auth";
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const LoginHistory = () => {
    const [loginHistory, setLoginHistory] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(10);
    const [searchEmail, setSearchEmail] = useState('');
    const auth = getAuth();

    const fetchLoginHistory = async (page, limit, email) => {
        try {
            const idToken = await auth.currentUser.getIdToken(true);

            // Fetch login history from the backend
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/login-history?page=${page}&limit=${limit}&email=${email}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                }
            });
            const data = await response.json();
            setLoginHistory(data.loginHistory);
            setHasMore(data.hasMore);
        } catch (error) {
            console.error('Error fetching login history:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLoginHistory(currentPage, pageSize, searchEmail);
    }, [currentPage, pageSize]);

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        setCurrentPage(1); // Reset to the first page when search is submitted
        setLoading(true); // Set loading state to true when applying search
        fetchLoginHistory(1, pageSize, searchEmail); // Fetch login history with search criteria
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <div className="container mt-4">
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button className="btn btn-primary mb-5" onClick={() => fetchLoginHistory(currentPage, pageSize, searchEmail)}>Actualiser</button>
            </div>

            {/* Search form */}
      {/*      <Form className="mb-4" onSubmit={handleSearchSubmit}>
                <Row className="align-items-center">
                    <Col md={6} className="mb-3 mb-md-0">
                        <Form.Group controlId="searchEmail">
                            <Form.Label className="form-label">Search Email:</Form.Label>
                            <Form.Control type="text" className="form-control" value={searchEmail} onChange={(e) => setSearchEmail(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col md={6} className="d-flex justify-content-end">
                        <Button type="submit" className="btn btn-primary">Rechercher</Button>
                    </Col>
                </Row>
            </Form>*/}

            {/* Render login history in a table */}
            <Table striped hover>
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Email</th>
                    <th>IP Address</th>
                    <th>Géolocalisation</th>
                </tr>
                </thead>
                <tbody>
                {/* Render loading message while fetching data */}
                {loading && <tr><td colSpan={4} className="text-center">Loading ...</td></tr>}
                {!loading && loginHistory.map((login) => (
                    <tr key={login.id}>
                        <td>{new Date(login.date).toLocaleString()}</td>
                        <td>{login.email}</td>
                        <td>{login.ipAddress}</td>
                        <td>{login.location.latitude ? `Lt: ${login.location.latitude.toFixed(4)}° ${login.location.latitude > 0 ? 'N' : 'S'} / Lg: ${Math.abs(login.location.longitude).toFixed(4)}° ${login.location.longitude > 0 ? 'E' : 'W'}` : ''}</td>
                    </tr>
                ))}
                </tbody>
            </Table>

            {/* Pagination controls */}
            <div className="d-flex justify-content-center mt-4">
                <Button
                    variant={currentPage === 1 ? "outline-dark" : "outline-primary"}
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                >
                    Previous
                </Button>
                <span className="mx-3">Page {currentPage}</span>
                <Button
                    variant={ !hasMore ? "outline-dark" : "outline-primary"}
                    disabled={!hasMore}
                    onClick={() => handlePageChange(currentPage + 1)}
                >
                    Next
                </Button>
            </div>
        </div>
    );
};

export default LoginHistory;