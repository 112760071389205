import React, { useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import TransactionsList from '../components/TransactionsHistory';

const TransactionsTab = ({ validation }) => {
    const [activeTab, setActiveTab] = useState('validation');

    return (
        <div className="container mt-4">
            {/*<h1 className="text-center my-5 text-blue"> {validation ? 'VALIDATION VIREMENT BANCAIRE' : 'HISTORIQUES DES TRANSACTIONS'}</h1>*/}

            {/*<Tab.Container activeKey={activeTab} onSelect={(key) => setActiveTab(key)}>
                <Nav variant="tabs">
                    <Nav.Item>
                        <Nav.Link eventKey="validation">VALIDATION</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="liste">LISTE</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="validation">*/}
                        <TransactionsList validation={true} />
            {/*        </Tab.Pane>*/}
            {/*        <Tab.Pane eventKey="liste">*/}
            {/*            /!*  LISTE DES VIREMENTS  *!/*/}
            {/*        </Tab.Pane>*/}
            {/*    </Tab.Content>*/}
            {/*</Tab.Container>*/}
        </div>
    );
};

export default TransactionsTab;
