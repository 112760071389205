import React, {useState, useEffect} from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FilterForm from './FilterForm';
import {getAuth} from 'firebase/auth';
import {Link} from 'react-router-dom';

const TransactionsHistory = () => {
    const [transactionHistory, setTransactionHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const auth = getAuth();
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [pageSize, setPageSize] = useState(15);
    const [disableUpdate, setDisableUpdate] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);

    useEffect(() => {
        setLoading(true);
        fetchTransactions();
    }, [currentPage, pageSize]);

    const fetchTransactions = async (criteria) => {
        try {
            const idToken = await auth.currentUser.getIdToken(true);
            const url = `${process.env.REACT_APP_API_URL}/transactions/myTransactions?&page=${currentPage}&limit=${pageSize}`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify(criteria)
            });

            const data = await response.json();
            setTransactionHistory(data.transactions);
            setHasMore(data.hasMore);
        } catch (error) {
            console.error('Error fetching transactions:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleFilterSubmit = (criteria) => {
        setCurrentPage(1); // Reset to the first page when filters change
        setLoading(true); // Set loading state to true when applying filters
        fetchTransactions({criteria}); // Fetch transactions after filters are updated
    };

    const handleAction = async (action, email, transactionID) => {
        setSelectedTransaction({action, email, transactionID});
        setShowConfirmModal(true);
    };

    const confirmAction = async () => {
        const {action, email, transactionID} = selectedTransaction;
        const endpoint = action === 'validate' ? '/transactions/validate' : '/transactions/refuse';

        try {
            setDisableUpdate(true);

            const idToken = await auth.currentUser.getIdToken(true);
            const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}`},
                body: JSON.stringify({email, transactionID}),
            });
            const data = await response.json();
            if (data.success) {
                console.log(`Transaction ${action}d successfully.`);
                fetchTransactions();  // Refresh the transactions list
            } else {
                console.error(data.error || `Failed to ${action} transaction.`);
            }
        } catch (error) {
            console.error(`Error ${action}ing transaction:`, error);
        } finally {
            setDisableUpdate(false);
            setShowConfirmModal(false);
        }
    };

    const handleLimitChange = (e) => {
        const newLimit = parseInt(e.target.value);
        setPageSize(newLimit);
    };

    const getType = (transaction_type) => {
        switch (transaction_type) {
            case 'generation_voucher':
                return 'vendre';
            case 'voucher recharge':
                return 'acheter';
            default:
                return transaction_type;
        }
    }

    return (
        <div className="container mt-4">
            <h1 className="text-center my-5 text-blue">HISTORIQUE DES TRANSACTIONS</h1>

            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <button className="btn btn-primary mb-5" onClick={() => fetchTransactions()}>Actualiser</button>
            </div>
            <FilterForm
                onFilterSubmit={handleFilterSubmit}
            />

            {loading && <p>Chargement ...</p>}
            {transactionHistory.length === 0 ? (
                !loading && <p>Aucune transaction</p>
            ) : (
                <>
                    {!loading && (
                        <Table striped hover>
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Date</th>
                                <th>Référence</th>
                                <th>Expéditeur</th>
                                <th>Types de transaction</th>
                                <th>Mode de Paiement</th>
                                <th>Montant</th>
                                <th>Devise</th>
                                <th>Status</th>
                                <th className="text-center">Detail</th>
                            </tr>
                            </thead>
                            <tbody>
                            {transactionHistory.map((transaction) => (
                                <tr key={transaction.id}>
                                    <td></td>
                                    <td>{new Date(transaction.timestamp).toLocaleString()}</td>
                                    <td></td>
                                    <td>{transaction.user_email}</td>
                                    <td>{getType(transaction.transaction_type)}</td>
                                    <td></td>
                                    <td>{Math.abs(transaction.amount).toLocaleString()}</td>
                                    <td>{transaction.devise}</td>
                                    <td>{transaction.state}</td>
                                    <td className="text-center">
                                        <Link to="/transaction-details" className="me-2" state={{
                                            transactionId: transaction.id,
                                            userID: transaction.userId
                                        }}>
                                            <i className="bi bi-file-earmark-text"></i>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    )}
                    {/* Pagination controls */}
                    <div className="d-flex justify-content-center mt-4">
                        <div className="btn-group me-2" role="group" aria-label="First group">
                            <button disabled={currentPage === 1} onClick={() => handlePageChange(1)}
                                    type="button" className="btn btn-outline-secondary d-flex align-items-center"
                                    style={{height: '30px'}}>
                                <i className="bi bi-chevron-double-left mx-auto"></i>
                            </button>
                            <button type="button" className="btn btn-outline-secondary d-flex align-items-center"
                                    disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}
                                    style={{height: '30px'}}>
                                <i className="bi bi-chevron-left mx-auto"></i>
                            </button>
                            <button type="button"
                                    className="btn btn-outline-secondary text-blue d-flex align-items-center justify-content-center"
                                    disabled={true} style={{height: '30px'}}>
                                {currentPage}
                            </button>
                            <button type="button" className="btn btn-outline-secondary d-flex align-items-center"
                                    disabled={!hasMore} onClick={() => handlePageChange(currentPage + 1)}
                                    style={{height: '30px'}}>
                                <i className="bi bi-chevron-right mx-auto"></i>
                            </button>
                            <button type="button" className="btn btn-outline-secondary d-flex align-items-center"
                                    disabled={!hasMore} onClick={() => handlePageChange(currentPage + 1)}
                                    style={{height: '30px'}}>
                                <i className="bi bi-chevron-double-right mx-auto"></i>
                            </button>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <span style={{marginRight: '10px'}}>Afficher:</span>
                            <input type="number" className="form-control" min="15" step="15" value={pageSize}
                                   onChange={handleLimitChange} style={{width: '70px', height: '30px'}}/>
                        </div>
                    </div>
                </>
            )}

            {/* Confirm Modal */}
            <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Voulez-vous
                    vraiment {selectedTransaction?.action === 'validate' ? 'valider' : 'refuser'} cette
                    transaction?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>Annuler</Button>
                    <Button variant="primary" onClick={confirmAction}>Confirmer</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default TransactionsHistory;