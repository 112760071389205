import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { Alert } from "react-bootstrap";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
    const navigate = useNavigate();
    const auth = getAuth();

    const traduireMessageErreur = (errorCode) => {
        switch (errorCode) {
            case 'auth/invalid-credential':
                return "Adresse Email ou Mot de Passe erroné";
            case 'auth/invalid-email':
                return "L'adresse email fournie est invalide.";
            case 'auth/user-disabled':
                return "Ce compte utilisateur a été désactivé.";
            case 'auth/user-not-found':
                return "Aucun utilisateur trouvé avec cet email.";
            case 'auth/wrong-password':
                return "Le mot de passe est incorrect.";
            default:
                return "Erreur de connexion. Veuillez réessayer.";
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Get Firebase authentication token
            const idToken = await user.getIdToken();

            // Call login API with user's email, authentication token
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({ email: email })
            });

            if (response.ok) {
                // Redirect user based on role if login successful
                const idTokenResult = await user.getIdTokenResult();
                if (idTokenResult.claims.role === 'admin') {
                    navigate('/');
                } else if (idTokenResult.claims.role === 'agent') {
                    navigate('/agent');
                } else if (idTokenResult.claims.role === 'cashpoint') {
                    navigate('/cashpoint');
                } else if (idTokenResult.claims.role === 'marchand') {
                    navigate('/marchand');
                } else {
                    setErrorMessage('Access refusé. Accès réservé aux admins.');
                }
            } else {
                // Handle error if logAuth call fails
                setErrorMessage('Erreur de connexion. Veuillez réessayer.');
            }
        } catch (error) {
            // Handle login errors
            setErrorMessage(traduireMessageErreur(error.code));
        }
    };

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="card-title text-center">Connexion</h3>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Adresse Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        placeholder="Entrez votre email"
                                        required
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">Mot de passe</label>
                                    <div className="input-group">
                                        <input
                                            type={showPassword ? "text" : "password"} // Use state to determine input type
                                            className="form-control"
                                            id="password"
                                            placeholder="Entrez votre mot de passe"
                                            autoComplete="current-password"
                                            required
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary"
                                            onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                                        >
                                            {showPassword ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>} {/* Show eye icon based on password visibility */}
                                        </button>
                                    </div>
                                </div>
                                {errorMessage && <Alert variant="danger" className="mt-3">{errorMessage}</Alert>}
                                <button type="submit" className="btn btn-primary w-100">Se connecter</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;