import React, {useState, useEffect} from 'react';
import {Row, Col, Form, Button, Modal} from 'react-bootstrap';
import {useLocation} from 'react-router-dom';
import QRCode from 'qrcode.react';

const AcheterARC = () => {
    const location = useLocation(); // Initialize useLocation hook
    const [transactionData, setTransactionData] = useState(null); // State to store transaction data
    const [voucher, setVoucher] = useState({}); // State to store transaction data

    useEffect(() => {
        // Check if location state contains transaction data
        if (location.state && location.state.formValues) {
            setTransactionData(location.state.formValues);
        }
        if (location.state && location.state.data) {
            setVoucher(location.state.data.vouchers[0]);
            console.log(location.state.data);
        }
    }, [location.state]); // Update state when location state changes

    return (
        <div className="container mt-5">
            <h1 className="text-center mt-5 text-blue">CREATION DU BON D’ACHAT FPAY</h1>
            {/* Display transaction data */}
            {transactionData && (
                <>
                    <p className="text-center text-blue fw-bolder mb-5">Montant de
                        : {voucher.amount.toLocaleString()} ARC</p>

                    <div className="mt-5" style={{display: 'flex', justifyContent: 'center'}}>
                        <div>
                            <img src="/images/voucher.jpg" width={400} alt="voucher"/>
                            <div className="my-4 text-center"><QRCode value={voucher.code}/></div>
                            <p className="text-center fw-bolder m-0">{voucher.code}</p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default AcheterARC;
