import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const FilterForm = ({ onFilterSubmit }) => {
    const [filterValue, setFilterValue] = useState('');

    const handleFilterChange = (value) => {
        setFilterValue(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onFilterSubmit(filterValue);
    };

    const clearFilterValue = () => {
        setFilterValue('');
    };

    return (
        <Form className="mb-4" onSubmit={handleSubmit}>
            <div className="position-relative">
                <i className="bi bi-search position-absolute top-50 start-0 translate-middle-y ms-2 text-muted"></i>
                <Form.Control
                    type="text"
                    value={filterValue}
                    onChange={(e) => handleFilterChange(e.target.value)}
                    style={{ paddingLeft: '30px' }} // Adjust padding-left to accommodate the icon
                />
                {filterValue && (
                    <i
                        className="bi bi-x position-absolute top-50 end-0 translate-middle-y me-2 text-muted"
                        onClick={clearFilterValue}
                        style={{ cursor: 'pointer' }}
                    ></i>
                )}
            </div>
            <Button style={{ display: 'none' }} variant="primary" type="submit">Appliquer</Button>
        </Form>
    );
};

export default FilterForm;
