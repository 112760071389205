import React, {useState} from 'react';
import {Tab, Nav} from 'react-bootstrap';
import Users from "./Users";
import Admins from "./Admins";
import Agents from "./Agents";
import LoginHistory from "./LoginHistory";
import Marchands from "./Marchands";

const MyTabs = () => {
    const [activeTab, setActiveTab] = useState('administrateurs');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="container mt-4">
            <h1 className="text-center my-5 text-blue">UTILISATEURS</h1>

            <Tab.Container activeKey={activeTab} onSelect={handleTabChange}>
                <div>
                    <Nav variant="tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="administrateurs">Administrateurs</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="agents">Agents comptables</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="fpay">Agents FPay</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="clients">Clients</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="cashpoint">Cash Point</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="marchands">Marchands</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="historiques">Historique des logins</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>

                <Tab.Content>
                    <Tab.Pane eventKey="administrateurs">
                        <Marchands role="admin"/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="agents">
                        <Marchands role="agent"/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fpay">
                        <Marchands role="fpay"/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="clients">
                        <Users/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="cashpoint">
                        <Marchands role="cashpoint"/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="marchands">
                        <Marchands role="marchand"/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="historiques">
                        <LoginHistory/>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div>
    );
};

export default MyTabs;
