import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import QRCode from 'qrcode.react';

const PrintableVouchersModal = ({ vouchers, showModal, setShowModal }) => {
    const handlePrint = () => {
        window.print();
    };

    return (
        <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
            <Modal.Body id="modal-body" className="text-center">
            <h1 className="text-center text-blue">BON D'ACHAT FPAY</h1>
                {vouchers.map((voucher, index) => (
                    <div key={index}>
                        <p className="text-center my-5 text-blue">Bon d'achat d'un montant de : {voucher.amount} ARC</p>
                        <QRCode value={voucher.code} />
                        <p className="mt-5"><strong>{voucher.code}</strong></p>
                    </div>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handlePrint}>Imprimer</Button>
                <Button variant="secondary" onClick={() => setShowModal(false)}>Fermer</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PrintableVouchersModal;
