import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { getAuth } from "firebase/auth";

const BankAccountLines = () => {
    const [lines, setLines] = useState([]);
    const [loading, setLoading] = useState(true);
    const auth = getAuth();

    const fetchBankAccountLines = async () => {
        try {
            const idToken = await auth.currentUser.getIdToken(true);

            // Fetch bank account lines from the backend
            const response = await fetch(`https://compta.fanampiana.com/api/index.php/bankaccounts/8/lines?sqlfilters=(label%3Alike%3A'MDT%25')%20`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'DOLAPIKEY': process.env.REACT_APP_DOLIBARR_API_TOKEN
                }
            });
            const data = await response.json();
            setLines(data); // Assuming data is an array of bank account lines
        } catch (error) {
            console.error('Error fetching bank account lines:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBankAccountLines();
    }, []);

    return (
        <div className="container mt-4">
            <h2>Liste des virements</h2>

            {/* Render bank account lines in a table */}
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Réf</th>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Montant</th>
                    {/* Add more headers as needed */}
                </tr>
                </thead>
                <tbody>
                {/* Render loading message while fetching data */}
                {loading && <tr><td colSpan={4} className="text-center">Chargements...</td></tr>}
                {!loading && lines.map((line) => (
                    <tr key={line.id}>
                        <td>{line.ref}</td>
                        <td>{line.num_releve}</td>
                        <td>{line.label}</td>
                        <td>{line.amount}</td>
                        {/* Render more columns as needed */}
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
    );
};

export default BankAccountLines;
