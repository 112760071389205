import React, {useEffect, useState} from 'react';
import {Row, Col, Form, Button, Modal, Alert} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {getAuth} from "firebase/auth";
import Spinner from "react-bootstrap/Spinner";

const AcheterARC = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false); // State to manage modal visibility
    const [userEmail, setUserEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [operateur, setOperateur] = useState('airtel');

    const [fees, setFees] = useState(null);
    const [loading, setLoading] = useState(true);
    const [cashpoint_client, setCashpoint_client] = useState([]);
    const [agentfpay_client, setAgentfpay_client] = useState([]);
    const [agentfpay_cashpoint, setAgentfpay_cashpoint] = useState([]);
    const [fee, setFee] = useState(0);
    const [solde, setSolde] = useState(0);
    const [qrCode, setQrCode] = useState(''); // State for QR code input
    const [showQrModal, setShowQrModal] = useState(false); // State to manage QR modal visibility
    const [showConfirmModal, setShowConfirmModal] = useState(false); // State to manage confirmation modal visibility
    const [confirmAction, setConfirmAction] = useState('');
    const auth = getAuth();

    const [formValues, setFormValues] = useState({
        acheter: 'en ligne', devise: 'euro', modePaiement: 'virement', montant: '', transaction: 'oui'
    }); // State to store form values

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setUserEmail(user.email);
            } else {
                setUserEmail('');
            }
        });

        return () => unsubscribe();
    }, []);

    const fetchFees = async () => {
        setLoading(true);
        try {
            const idToken = await auth.currentUser.getIdToken(true);

            const response = await fetch(`${process.env.REACT_APP_API_URL}/transactions/fees`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch fees');
            }
            const data = await response.json();

            console.log("data", data);

            // Set the fetched fees data to state
            setFees(data);
            setCashpoint_client(data.Vendre.cashpoint_client);
            setAgentfpay_cashpoint(data.Vendre.agentfpay_cashpoint);
            setAgentfpay_client(data.Vendre.agentfpay_client);
        } catch (error) {
            console.error('Error fetching fees:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchSolde = async () => {
        setLoading(true);
        try {
            // Get the ID token of the current user
            const idToken = await auth.currentUser.getIdToken(true);

            // Get the UID of the current user
            const uid = auth.currentUser.uid;

            // Make the API request with the ID token and UID
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${uid}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch solde');
            }

            const data = await response.json();

            setSolde(data.solde);
        } catch (error) {
            console.error('Error fetching fees:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFees();
        fetchSolde();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Check if mode de paiement is "virement"
        if (formValues.modePaiement === 'virement') {
            // Redirect to "/ARC/acheter/virement"
            navigate('/ARC/vendre/virement', {state: {formValues}});
        } else if (formValues.modePaiement === 'mobile') {
            // Redirect to "/ARC/acheter/virement"
            navigate('/ARC/vendre/mobile', {state: {formValues}});
        } else {
            // Perform form submission logic here
            // For demonstration purposes, just display the modal
            setShowModal(true);
        }
    };

    // Function to handle input changes and update formValues state
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        let updatedModePaiement = '';
        if (name === 'devise') {
            // Update modePaiement based on the selected devise
            if (formValues.acheter === 'en ligne') {
                updatedModePaiement = value === 'ariary' ? 'virement' : 'carte';
            } else {
                updatedModePaiement = 'especes';
            }
            setFormValues({...formValues, [name]: value, modePaiement: updatedModePaiement});
        } else if (name === 'acheter') {
            // Update modePaiement based on the selected devise
            if (value === 'en ligne') {
                updatedModePaiement = 'virement';
            } else {
                updatedModePaiement = 'especes';
            }
            setFormValues({...formValues, [name]: value, modePaiement: updatedModePaiement});
        } else {
            setFormValues({...formValues, [name]: value});
        }

        if (name === 'montant') {
            let frais = 0;

            if (formValues.acheter === 'en ligne') {
                const agentfpay_client_data = formValues.devise === 'euro' ? agentfpay_client.euro : agentfpay_client.ariary;

                if (formValues.devise === 'euro') {
                    frais = findFee(agentfpay_client_data, formValues.modePaiement, 'int_', '@agentfpay_client', value);
                } else if (formValues.devise === 'ariary') {
                    frais = formValues.modePaiement !== 'mobile' ?
                        findFee(agentfpay_client_data, formValues.modePaiement, '', '@agentfpay_client', value) :
                        findFee(agentfpay_client_data, operateur, '', '@agentfpay_client', value)
                    ;
                }
            } else if (formValues.acheter === 'cashpoint') {
                const cashpoint_client_data = formValues.devise === 'euro' ? cashpoint_client.euro : cashpoint_client.ariary;

                if (formValues.devise === 'euro') {
                    frais = findFee(cashpoint_client_data, formValues.modePaiement, 'int_', '@cashpoint_client', value);
                } else if (formValues.devise === 'ariary') {
                    frais = formValues.modePaiement !== 'mobile' ?
                        findFee(cashpoint_client_data, formValues.modePaiement, '', '@cashpoint_client', value) :
                        findFee(cashpoint_client_data, operateur, '', '@cashpoint_client', value)
                    ;
                }
            }

            setFee(value - parseFloat(frais));
        }
    };

    function findFee(clientData, modePaiement, prefix, postfix, amount) {
        const modePaiementKey = `${prefix}${modePaiement}${postfix}`;
        const intervals = clientData[modePaiementKey] || [];

        return intervals.reduce((acc, interval) => {
            if (amount >= interval.min && amount <= interval.max) {
                return interval.amount;
            }
            return acc;
        }, 0);
    }

    const generateVoucher = async () => {
        try {
            const idToken = await auth.currentUser.getIdToken(true);

            const response = await fetch(`${process.env.REACT_APP_API_URL}/transactions/generate_voucher/vendre`, {
                method: 'POST', headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                }, body: JSON.stringify({
                    email: userEmail, fee, amount: formValues.montant, num_codes: 1
                })
            });

            const data = await response.json();
            if (response.ok) {
                setErrorMessage('');

                navigate('/ARC/acheter/voucher', {state: {formValues, data}});
            } else {
                switch (data.error) {
                    case "Insufficient funds":
                        setErrorMessage("Solde insuffisant");
                        break;
                    default:
                        setErrorMessage(data.error);
                        break;
                }
            }

            /* const data = {vouchers: [{code: 'JZHFQ890'}]}
             navigate('/ARC/acheter/voucher', {state: {formValues, data}});*/
        } catch (error) {
            console.error('Error:', error);

        }
    }

    const handleGenerateVoucher = async (e) => {
        e.preventDefault();
        setConfirmAction('generateVoucher');
        setShowConfirmModal(true);
    };

    const handleScan = (e) => {
        e.preventDefault();
        setShowQrModal(true); // Show the QR code modal
    };

    const submitQR = async () => {
        try {
            const idToken = await auth.currentUser.getIdToken(true);

            const response = await fetch(`${process.env.REACT_APP_API_URL}/transactions/use_voucher/acheter`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({
                    email: userEmail,
                    voucher_code: qrCode,
                })
            });

            const data = await response.json();
            if (response.ok) {
                setErrorMessage('');
                // Handle success (e.g., navigate to a success page or show a success message)
                console.log('Voucher used successfully:', data);
                fetchSolde();
                setShowQrModal(false); // Close the modal on success
            } else {
                setErrorMessage(data.error || 'Failed to use voucher');
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('Failed to use voucher');
        }
    }

    const handleQrSubmit = async (e) => {
        e.preventDefault();
        // setConfirmAction('submitQR');
        // setShowConfirmModal(true);
        submitQR();
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
                <Spinner animation="border"/>
            </div>
        );
    }

    return (<div className="container mt-5">
            <h1 className="text-center mt-5 text-blue">VENDRE ARIARYCOIN</h1>
            <p className="text-center">Votre Solde : {solde.toLocaleString()} ARC</p>

            <Form
                className="mt-5"
                style={{maxWidth: '456px', marginLeft: '100px'}}
            >
                <Row className="mb-3">
                    <Col xs={5} className="d-flex align-items-center">
                        <Form.Label className="mb-0">Voulez-vous vendre :</Form.Label>
                    </Col>
                    <Col xs={7}>
                        <Form.Select
                            name="acheter"
                            aria-label="Sélectionnez votre réponse"
                            value={formValues.acheter}
                            onChange={handleInputChange}
                        >
                            <option value="en ligne">En ligne</option>
                            <option value="cashpoint">Cash Point</option>
                        </Form.Select>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col xs={5} className="d-flex align-items-center">
                        <Form.Label className="mb-0">Choisir Devise :</Form.Label>
                    </Col>
                    <Col xs={7}>
                        <Form.Select
                            name="devise"
                            aria-label="Sélectionnez votre réponse"
                            value={formValues.devise}
                            onChange={handleInputChange}
                        >
                            <option value="euro">Euro</option>
                            <option value="ariary">Ariary</option>
                        </Form.Select>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col xs={5} className="d-flex align-items-center">
                        <Form.Label className="mb-0">Choisir Mode de Paiement :</Form.Label>
                    </Col>
                    <Col xs={7}>
                        <Form.Select
                            name="modePaiement"
                            aria-label="Sélectionnez votre réponse"
                            value={formValues.modePaiement}
                            onChange={handleInputChange}
                        >
                            {formValues.acheter === "cashpoint" ? <>
                                    <option value="especes">Espèces</option>
                                    {formValues.devise === 'ariary' && <option value="mobile">Mobile Money</option>}
                                </> :
                                <>
                                    {formValues.devise === 'euro' && <option value="carte">Carte Bleu</option>}
                                    {formValues.devise === 'euro' && <option value="paypal">Paypal</option>}
                                    <option value="virement">Virement Bancaire</option>
                                    {formValues.devise !== 'euro' && <option value="mobile">Mobile Money</option>}
                                </>
                            }

                        </Form.Select>
                    </Col>
                </Row>

                {formValues.modePaiement === 'mobile' &&
                    <Row className="mb-3">
                        <Col xs={5} className="d-flex align-items-center">
                            <Form.Label className="mb-0">Choisir Opérateur :</Form.Label>
                        </Col>
                        <Col xs={7}>
                            <Form.Select
                                name="modePaiement"
                                aria-label="Sélectionnez votre réponse"
                                value={operateur}
                                onChange={(e) => setOperateur(e.target.value)}
                            >
                                <option value="airtel">Airtel Money</option>
                                <option value="telma">Mvola</option>
                                <option value="orange">Orange Money</option>
                            </Form.Select>
                        </Col>
                    </Row>
                }

                {formValues.acheter === 'cashpoint' && <Row className="mb-3">
                    <Col xs={5} className="d-flex align-items-center">
                        <Form.Label className="mb-0">Voulez-vous créer la transaction?</Form.Label>
                    </Col>
                    <Col xs={7}>
                        <Form.Select
                            name="transaction"
                            aria-label="Sélectionnez votre réponse"
                            value={formValues.transaction}
                            onChange={handleInputChange}
                        >
                            <option value="oui">OUI</option>
                            <option value="non">NON</option>
                        </Form.Select>
                    </Col>
                </Row>}

                {!(formValues.acheter === 'cashpoint' && formValues.transaction === 'non') &&
                    <>
                        <Row className="mb-3">
                            <Col xs={5} className="d-flex align-items-center">
                                <Form.Label className="mb-0">Entrer montant :</Form.Label>
                            </Col>
                            <Col xs={7}>
                                <Form.Control
                                    type="number"
                                    placeholder="Entrez le montant"
                                    name="montant"
                                    value={formValues.montant}
                                    onChange={handleInputChange}
                                    min={1000}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col xs={5} className="d-flex align-items-center">
                                <Form.Label className="mb-0">MONTANT ARC</Form.Label>
                            </Col>
                            <Col xs={7}>
                                <Form.Control
                                    type="number"
                                    placeholder="Entrez le montant"
                                    name="montant"
                                    value={fee}
                                    min={1}
                                    disabled
                                />
                            </Col>
                        </Row>
                    </>
                }

                <Row className="mb-3">
                    <Col xs={5} className="d-flex align-items-center"></Col>
                    <Col xs={7}>
                        {(formValues.acheter === 'cashpoint' && formValues.transaction === 'oui') &&
                            <Row className="justify-content-center mb-3">
                                <Col xs="auto">
                                    {errorMessage && <Alert className="my-2" variant="danger">{errorMessage}</Alert>}
                                    <Button variant="primary" type="submit" onClick={handleGenerateVoucher}>
                                        Générer Bon d’Achat
                                    </Button>
                                </Col>
                            </Row>
                        }
                        {(formValues.acheter === 'cashpoint' && formValues.transaction === 'non') &&
                            <Row className="justify-content-center mb-3">
                                <Col xs="auto">
                                    {errorMessage && <Alert className="my-2" variant="danger">{errorMessage}</Alert>}
                                    <Button variant="primary" type="submit" onClick={handleScan}>
                                        Scanner QR code
                                    </Button>
                                </Col>
                            </Row>
                        }
                        {!(formValues.acheter === 'cashpoint' && formValues.transaction === 'oui') &&
                            !(formValues.acheter === 'cashpoint' && formValues.transaction === 'non') &&
                            <Row className="justify-content-center mb-3">
                                <Col xs="auto">
                                    <Button variant="success" type="submit" onClick={handleSubmit}>
                                        Valider
                                    </Button>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            </Form>

            {/* Modal for transaction confirmation */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton className="blue-header">
                    <Modal.Title>CONFIRMATION TRANSACTION</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center">
                        Votre transaction d’un montant {formValues.montant} ARC par {formValues.modePaiement} sous la
                        référence : ........ est
                        réussie.
                    </p>
                    <p className="text-center mb-0 text-blue fw-bolder">Merci pour votre confiance!</p>
                    <p className="text-center text-blue fw-bolder">L'équipe FPay</p>
                </Modal.Body>
            </Modal>

            {/* Modal for QR code input */}
            <Modal show={showQrModal} onHide={() => setShowQrModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Scanner QR code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleQrSubmit}>
                        <Form.Group controlId="formQrCode">
                            <Form.Label>Entrez le code QR</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Code QR"
                                value={qrCode}
                                onChange={(e) => setQrCode(e.target.value)}
                            />
                        </Form.Group>
                        {errorMessage && <Alert className="my-2" variant="danger">{errorMessage}</Alert>}
                        <Button variant="primary" type="submit" className="mt-3">
                            Valider
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal for transaction confirmation */}
            <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
                <Modal.Header closeButton className="blue-header">
                    <Modal.Title>CONFIRMATION TRANSACTION</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center">
                        Voulez-vous confirmer la génération d'un voucher d'un montant de {formValues.montant} ARC?
                    </p>
                    <p>Frais de transaction : {formValues.montant - fee} ARC</p>

                    <Row className="justify-content-center mb-3">
                        <Col xs="auto">
                            <Button variant="success" onClick={generateVoucher}>
                                Confirmer
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AcheterARC;
