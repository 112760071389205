import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import {initializeApp} from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyD45oaFTgZvGRHJZZNoxZk00m3wS2SDlkA",
    authDomain: "fpay-1c246.firebaseapp.com",
    projectId: "fpay-1c246",
    storageBucket: "fpay-1c246.appspot.com",
    messagingSenderId: "1072205101654",
    appId: "1:1072205101654:web:8e8d19d1fbe40b234359f7",
    measurementId: "G-RXSTM1GQDV"
};

// Initialize Firebase
initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
