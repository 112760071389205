import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { Button, Table } from 'react-bootstrap';
import PrintableVouchersModal from './PrintableVouchersModal';

const VoucherList = () => {
    const [vouchers, setVouchers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedVouchers, setSelectedVouchers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const auth = getAuth();

    const fetchVouchers = async () => {
        try {
            setLoading(true);
            const user = auth.currentUser;
            if (!user) {
                throw new Error('User not authenticated');
            }
            const idToken = await user.getIdToken(true);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/transactions/vouchers`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch vouchers');
            }
            const data = await response.json();
            setVouchers(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching vouchers:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchVouchers();
    }, []);

    const handleDetailsClick = (selectedVouchers) => {
        setSelectedVouchers(selectedVouchers);
        setShowModal(true);
    };

    const handleRefresh = () => {
        fetchVouchers();
    };

    return (
        <div className="container mt-4">
            <div className="d-flex justify-content-between align-items-center">
                <h1 className="text-center mt-5 text-blue">Liste des bons d'achats</h1>
                <Button variant="primary" onClick={handleRefresh}><i className="bi bi-arrow-clockwise"></i> Actualiser</Button>
            </div>
            {loading ? (
                <p>Chargement...</p>
            ) : (
                <Table striped hover>
                    <thead>
                    <tr>
                        <th>Date de création</th>
                        <th>Code</th>
                        <th>Montant</th>
                        <th>Numéro de Commande</th>
                        <th>Statut</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {vouchers.map(voucher => (
                        <tr key={voucher.id}>
                            <td>{new Date(voucher.creation_date).toLocaleString()}</td>
                            <td>{voucher.code}</td>
                            <td>{voucher.amount.toLocaleString()} ARC</td>
                            <td></td>
                            <td>{voucher.status}</td>
                            <td>
                                <Button onClick={() => handleDetailsClick([voucher])}>Details</Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            )}
            <PrintableVouchersModal vouchers={selectedVouchers} showModal={showModal} setShowModal={setShowModal} />
        </div>
    );
};

export default VoucherList;
