import React, {useEffect, useState} from 'react';
import {getAuth, onAuthStateChanged, updatePassword, updateProfile} from 'firebase/auth';
import {Modal, Button} from 'react-bootstrap';
import QRCode from 'qrcode.react';

const Compte = () => {
    const [username, setUsername] = useState('');
    const [firstName, setfirstName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [entreprise, setEntreprise] = useState('');
    const [role, setRole] = useState(''); // State for password visibility toggle
    const [showPassword, setShowPassword] = useState(false); // State for password visibility toggle
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State for confirm password visibility toggle
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [country, setCountry] = useState('');
    const [uid, setUID] = useState('');
    const auth = getAuth();

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUsername(user.displayName || ''); // Set display name as default value for username
                setEmail(user.email || ''); // Set email as default value for email
                if (user.getIdTokenResult) {
                    user.getIdTokenResult().then((idTokenResult) => {
                        const customClaims = idTokenResult.claims;
                        setRole(customClaims.role || '');
                    });
                }
            }
        });
    }, [auth]);

    const fetchUserData = async () => {
        try {
            const user = auth.currentUser;
            if (!user) {
                throw new Error('User not authenticated');
            }
            const idToken = await user.getIdToken(true);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${ user.uid }`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch user');
            }
            const data = await response.json();
            setUID(data.uid);
            setPhoneNumber(data.phone || '');
            setEntreprise(data.entreprise || '');
            setfirstName(data.first_name || '');
            if (data.country) setCountry(data.country);
        } catch (error) {
            console.error('Error fetching user:', error);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
        const user = auth.currentUser;

        if (!user) {
            setErrorMessage('No user logged in');
            setShowErrorModal(true);
            return;
        }

        try {
            // const idTokenResult = await user.getIdTokenResult(true);

            // Check if user has the "agent" custom claim
          /*  if (idTokenResult.claims.role === 'agent') {
                setErrorMessage('Les Agents ne peuvent pas modifier leur compte.');
                setShowErrorModal(true);
                return;
            }*/

            if (password) {
                if (password !== confirmPassword) {
                    throw new Error("Mots de passe non correspondant");
                }
                await updatePassword(user, password);
            }

            // Update display name if the name changes
            if (user.displayName !== username) {
                await updateProfile(user, {displayName: username});
            }

            // CALL THE API TO UPDATE OTHER DATA
            if (!user) {
                throw new Error('User not authenticated');
            }
            const idToken = await user.getIdToken(true);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/updateUser`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({
                    display_name: username,
                    phone_number: phoneNumber,
                    entreprise: entreprise,
                    first_name: firstName
                })
            });
            if (!response.ok) {
                throw new Error('Failed to fetch user');
            }

            setShowSuccessModal(true);
        } catch (error) {
            setErrorMessage('Erreur: ' + error.message);
            setShowErrorModal(true);
        }
    };

    return (
        <>
            <h1 className="text-center mt-5 text-blue">MON COMPTE</h1>

            <div className="container" style={{maxWidth: "500px"}}>
                <div className="card mx-auto">
                    <div className="card-body">
                        <h5 className="card-title fw-bolder text-center my-3">Information du compte</h5>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <QRCode value={email} />
                        </div>
                        <p className="text-center mt-3">{uid}</p>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group mb-3">
                                <label htmlFor="username">Nom</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="username"
                                    placeholder="Votre nom"
                                    value={username}
                                    onChange={e => setUsername(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="username">Prénom</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="firstname"
                                    placeholder="Votre prénom"
                                    value={firstName}
                                    onChange={e => setfirstName(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="username">Entreprise</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="entreprise"
                                    placeholder="Votre entreprise"
                                    value={entreprise}
                                    onChange={e => setEntreprise(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="username">Pays</label>
                                <input type="text" className="form-control" id="country" value={country} disabled/>
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="role">Type de compte</label>
                                <div className="input-group">
                                    <select
                                        className="form-select"
                                        id="role"
                                        value={role}
                                        disabled={true}
                                    >
                                        <option value="">Choisir le type de compte...</option>
                                        <option value="admin">Administrateur</option>
                                        <option value="user">Utilisateur</option>
                                        <option value="agent">Agent</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="username">Téléphone</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="phoneNumber"
                                    placeholder="Votre numéro de téléphone"
                                    value={phoneNumber}
                                    onChange={e => setPhoneNumber(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="email">Adresse Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    value={email}
                                    disabled
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="password">Mot de passe</label>
                                <div className="input-group">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        className="form-control"
                                        id="password"
                                        placeholder="Mot de passe"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                    />
                                    <button
                                        className="btn btn-outline-secondary"
                                        type="button"
                                        onClick={() => setShowPassword(prevState => !prevState)}
                                    >
                                        {showPassword ? (
                                            <i className="bi bi-eye-slash"></i>
                                        ) : (
                                            <i className="bi bi-eye"></i>
                                        )}
                                    </button>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="confirmPassword">Confirmez le mot de passe</label>
                                <div className="input-group">
                                    <input
                                        type={showConfirmPassword ? "text" : "password"}
                                        className="form-control"
                                        id="confirmPassword"
                                        placeholder="Confirmez le mot de passe"
                                        value={confirmPassword}
                                        onChange={e => setConfirmPassword(e.target.value)}
                                    />
                                    <button
                                        className="btn btn-outline-secondary"
                                        type="button"
                                        onClick={() => setShowConfirmPassword(prevState => !prevState)}
                                    >
                                        {showConfirmPassword ? (
                                            <i className="bi bi-eye-slash"></i>
                                        ) : (
                                            <i className="bi bi-eye"></i>
                                        )}
                                    </button>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary me-3">Mettre à jour</button>
                            <button type="button" className="btn btn-secondary">Annuler</button>
                        </form>
                    </div>

                    {/* Success Modal */}
                    <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Modification Effectué</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Votre compte a été mis à jour avec succès.</Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => setShowSuccessModal(false)}>OK</Button>
                        </Modal.Footer>
                    </Modal>

                    {/* Error Modal */}
                    <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Erreur</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{errorMessage}</Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => setShowErrorModal(false)}>OK</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default Compte;
